const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const TICKETS_REQUEST = "TICKETS_REQUEST";
const TICKETS_ERROR = "TICKETS_ERROR";
const TICKETS_SUCCESS = "TICKETS_SUCCESS";
const TICKET_COMMENT_UPDATE = "TICKET_COMMENT_UPDATE";
const COMMENT_PUT_SUCCESS = "COMMENT_PUT_SUCCESS";
const COMMENT_UPDATE_ERROR = "COMMENT_UPDATE_ERROR";
const TICKET_FETCH = "TICKET_FETCH";
const TICKET_FETCH_SUCCESS = "TICKET_FETCH_SUCCESS";
const TICKET_FETCH_ERROR = "TICKET_FETCH_ERROR";
const TICKET_CREATE = "TICKET_CREATE";
const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
const TICKET_CREATE_ERROR = "TICKET_CREATE_ERROR";

import Vue from "vue";
import axios from 'axios';

const state = { status: "", tickets: [] };

const getters = {
    getTickets: state => state.tickets.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1)),
    getTicket: (state)=> (ticketid) => {
        return state.tickets.find(ticket => ticket.ticketid === ticketid)
      },
    isTicketsListLoaded: state => !!state.tickets,
    searchTickets: (state) => searchParam => {
        var searchTerm = new RegExp(searchParam, "i") //Tickets are searched by ID number, subject, and description contents.
        return state.tickets.filter((ticket => searchTerm.test(ticket.ticketid) || searchTerm.test(ticket.subject) || searchTerm.test(ticket.description)))
    }

};

const actions = {
    [TICKETS_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(TICKETS_REQUEST);
            commit('addLoading', {handle:'tickets',name:'Load Tickets'})
            axios({url: Vue.prototype.$apiServer + 'tickets', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'tickets')
                    if (resp.data.result === "Success") {
                        commit(TICKETS_SUCCESS, resp.data.payload)
                        resolve(resp.data)
                    } else {
                        commit(TICKETS_ERROR)
                    }
                })
                .catch((err) => {
                    commit(TICKETS_ERROR);
                    commit('removeLoading', 'tickets')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [TICKET_FETCH]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(TICKET_FETCH);
            commit('addLoading', {handle:'fetchticket'+payload.ticketID,name:'Retrieving Ticket #' + payload.ticketID})
            axios({
                url: Vue.prototype.$apiServer + 'ticket/' + payload.ticketID,
                method: "GET",
            })
            .then(resp => {
                commit('removeLoading', 'fetchticket'+payload.ticketID)
                if(resp.data.result === 'Success') {
                    commit(TICKET_FETCH_SUCCESS, resp.data.payload);
                    resolve(resp.data)
                } else {
                    commit(TICKET_FETCH_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(TICKET_FETCH_ERROR);
                commit('removeLoading', 'fetchticket'+payload.ticketID)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },

    [TICKET_CREATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(TICKET_FETCH);
            commit('addLoading', {handle:'createticket', name:'Creating Ticket'})
            axios({
                url: Vue.prototype.$apiServer + 'ticket',
                method: "POST",
                data: payload.ticketPayload
            })
                .then(resp => {
                    commit('removeLoading', 'createticket')
                    if(resp.data.result === 'Success') {
                        commit(TICKET_FETCH_SUCCESS, resp.data.payload);
                        resolve(resp.data)
                    } else {
                        commit(TICKET_FETCH_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(TICKET_FETCH_ERROR);
                    commit('removeLoading', 'createticket')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },


    [TICKET_COMMENT_UPDATE]: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            commit(TICKET_COMMENT_UPDATE);
            commit('addLoading', {handle:'addticketcomment'+payload.ticketID, name:'Adding Ticket Comment'})
            axios({
                url: Vue.prototype.$apiServer + 'ticket/' + payload.ticketID+'/comment',
                method: "POST",
                data: payload.payload
            })
            .then(resp => {
                commit('removeLoading', 'addticketcomment'+payload.ticketID)
                if(resp.data.result === 'Success') {
                    commit(COMMENT_PUT_SUCCESS, resp.data.payload);
                    resolve(resp.data)
                } else {
                    commit(COMMENT_UPDATE_ERROR);
                    reject(resp.data)
                }
            })
            .catch((err) => {
                commit(COMMENT_UPDATE_ERROR);
                commit('removeLoading', 'addticketcomment'+payload.ticketID)
                // if resp is unauthorized, logout, to
                if (err.response.status === 403) {
                    dispatch(AUTHTOKEN_LOGOUT);
                }
                reject(err.response.data)
            });
        })
    },
};

const mutations = {
    [TICKETS_REQUEST]: state => {
        state.status = "loading";
    },
    [TICKET_COMMENT_UPDATE]: state => {
        state.updatestatus = "loading";
    },
    [TICKETS_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "tickets", resp);
    },
    [TICKET_FETCH]: (state) => {
        state.ticketfetchstatus = "loading";
    },
    [TICKET_FETCH_SUCCESS]: (state) => {
        state.ticketfetchstatus = "success";
    },
    [TICKET_FETCH_ERROR]: (state) => {
        state.ticketfetchstatus = "error";
    },
    [TICKET_CREATE]: (state) => {
        state.ticketcreatestatus = "loading";
    },
    [TICKET_CREATE_SUCCESS]: (state) => {
        state.ticketcreatestatus = "success";
    },
    [TICKET_CREATE_ERROR]: (state) => {
        state.ticketcreatestatus = "error";
    },
    [COMMENT_PUT_SUCCESS]: (state) => {
        state.updatestatus = "success";
    },
    [TICKETS_ERROR]: state => {
        state.status = "error";
    },
    [COMMENT_UPDATE_ERROR]: state => {
        state.updatestatus = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.tickets = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};