<template>
  <b-navbar
    id="template-header"
    class="default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    type="dark"
    toggleable="lg"
  >
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
    >
      <router-link class="navbar-brand brand-logo mr-1 ml-1" to="/">
        <img src="@/assets/images/logo.svg" alt="logo" />
      </router-link>
      <router-link class="navbar-brand brand-logo-mini" to="/">
        <img src="@/assets/images/vernet.png" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button
        class="navbar-toggler navbar-toggler align-self-center d-lg-block"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="ti-layout-grid2"></span>
      </button>
      <div class="nav-search">
        <form @submit.prevent="searchBar">
          <div class="input-group">
            <div
              class="input-group-prepend hover-cursor"
              id="navbar-search-icon"
            >
              <span class="input-group-text" id="search">
                <i class="ti-search"></i>
              </span>
            </div>
            <!--<input
                type="text"
                class="form-control"
                id="searchParameter"
                v-model="searchParameter"
                placeholder="Search"
                aria-label="search"
                aria-describedby="search"
            />-->
            <VueFuse
              :list="searchPossibilities"
              :fuse-opts="searchOptions"
              :search="searchParameter"
              @fuse-input="(r) => (this.searchParameter = r)"
              :map-results="false"
              :defaultAll="false"
              id="searchBox"
              placeholder="Search"
              autocomplete="off"
              class="form-control"
              @fuse-results="handleResults"
            />
            <b-popover
              :show.sync="showResults"
              triggers="focus"
              placement="bottomright"
              target="searchBox"
              title="Search Results"
              variant="primary"
            >
              <div
                class="text-center text-muted m-2"
                v-if="searchParameter.length < 2"
              >
                Enter a search term
              </div>
              <div v-if="results.length > 0" class="container-fliud">
                <div v-for="(result, i) in results.slice(0, 4)" :key="i">
                  <div
                    v-if="Object.keys(result.item).includes('serviceid')"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/service/' + result.item.serviceid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + servicetype(result.item.type).color"
                    >
                      <em>Service:</em>
                      <strong>{{ result.item.serviceid }}</strong>
                    </p>
                    <p
                      class="card-body text-white m-0 p-0 pl-1 overflow-hidden"
                    >
                      {{ result.item.description }}
                    </p>
                  </div>
                  <div 
                    v-if="Object.keys(result.item).includes('siteid') && result.item.sitetype == 1"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/site/' + result.item.siteid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + getSiteStatus(result.item.siteid)"
                    >
                      <em>Site:</em>
                      <strong
                        >{{ result.item.sitename }} ({{
                          result.item.sitenumber
                        }})</strong
                      >
                    </p>
                    <p
                      class="card-body text-white m-0 p-0 pl-1 overflow-hidden"
                    >
                      {{ result.item.street }}, {{ result.item.suburb }}
                      {{ result.item.sitetype }}
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('siteid') && result.item.sitetype === 2"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/vpls/' + result.item.siteid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + getSiteStatus(result.item.siteid)"
                    >
                      <em>VPLS:</em>
                      <strong
                        >{{ result.item.sitename }} ({{
                          result.item.sitenumber
                        }})</strong
                      >
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('siteid') && result.item.sitetype === 3"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/site/' + result.item.siteid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + getSiteStatus(result.item.siteid)"
                    >
                      <em>Internet:</em>
                      <strong
                        >{{ result.item.sitename }} ({{
                          result.item.sitenumber
                        }})</strong
                      >
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('siteid') && result.item.sitetype === 4"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/l3vpn/' + result.item.siteid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + getSiteStatus(result.item.siteid)"
                    >
                      <em>L3VPN:</em>
                      <strong
                        >{{ result.item.sitename }} ({{
                          result.item.sitenumber
                        }})</strong
                      >
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('siteid') && result.item.sitetype === 5"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/site/' + result.item.siteid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + getSiteStatus(result.item.siteid)"
                    >
                      <em>Interconnect:</em>
                      <strong
                        >{{ result.item.sitename }} ({{
                          result.item.sitenumber
                        }})</strong
                      >
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('userid')"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/user/' + result.item.userid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + userColor(result.item.type)"
                    >
                      <em>User:</em>
                      <strong
                        >{{ result.item.firstname }}
                        {{ result.item.surname }}</strong
                      >
                    </p>
                    <p
                      class="card-body text-white m-0 p-0 pl-1 overflow-hidden"
                    >
                      {{ result.item.title }}
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('contactid')"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/contact/' + result.item.contactid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-primary'"
                    >
                      <em>Contact:</em>
                      <strong>{{ result.item.contactname }}</strong>
                    </p>
                    <p
                      class="card-body text-white m-0 p-0 pl-1 overflow-hidden"
                    >
                      {{ result.item.title }} ({{ result.item.sites.length }}
                      {{ "site" | pluralize(result.item.sites.length) }})
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('eventid')"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/event/' + result.item.eventid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-' + eventColour(result.item.type)"
                    >
                      <em>Event:</em>
                      <strong
                        >({{ result.item.number }}) -
                        {{ result.item.title }}</strong
                      >
                    </p>
                    <p
                      class="card-body text-white m-0 p-0 pl-1 overflow-hidden"
                    >
                      {{ result.item.type | eventType }}
                    </p>
                  </div>
                  <div
                    v-if="Object.keys(result.item).includes('equipmentid')"
                    class="card services-card-link text-white card-rounded mb-2 cursor-pointer"
                    @click="gotoRouter('/equipment/' + result.item.equipmentid)"
                  >
                    <p
                      class="card-title text-white mb-0 pl-1 pr-1 card-title-rounded"
                      v-bind:class="'bg-primary'"
                    >
                      <em>Equipment:</em>
                      <strong>{{ result.item.equipmentname }}</strong>
                    </p>
                    <p
                      class="card-body text-white m-0 p-0 pl-1 overflow-hidden"
                    >
                      {{ result.item.ipaddress }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                @click="searchBar"
                class="cursor-pointer text-right mt-2"
                v-if="results.length > 4"
              >
                {{ results.length - 4 }}+ more results for '{{
                  searchParameter
                }}' <i class="fa fa fa-arrow-right"></i>
              </div>
              <div
                @click="searchBar"
                class="cursor-pointer text-right mt-2"
                v-if="searchParameter.length > 1 && results.length < 5"
              >
                Advanced Search for '{{ searchParameter }}'
                <i class="fa fa-arrow-right"></i>
              </div>
            </b-popover>
          </div>
        </form>
      </div>
      <b-navbar-nav class="navbar-nav-right ml-auto">
        <b-nav-form right class="mr-0 d-none d-md-flex">
          <a :href="`tel:1300837638`">
            <button type="button" class="btn btn-inverse-primary btn-fw">
              <i class="mx-0 mr-1">
                <font-awesome-icon icon="phone"></font-awesome-icon>
              </i>
              1300 837 638
            </button>
          </a>
        </b-nav-form>
        <b-nav-item-dropdown right class="preview-list">
          <template slot="button-content">
            <div class="nav-link count-indicator dropdown-toggle">
              <i class="ti-bell mx-0"></i>
              <span class="count" v-if="showbell"></span>
            </div>
          </template>
          <h6 class="p-3 mb-0 text-white">Notifications</h6>
          <b-dropdown-item class="preview-item" v-if="problems.length > 0">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-danger">
                <i class="ti-alert mx-0"></i>
              </div>
            </div>
            <div
              class="preview-item-content"
              @click="$bvModal.show('HeaderProblems')"
            >
              <h6 class="preview-subject font-weight-normal mb-1">
                Network Problems
              </h6>
              <p class="font-weight-light small-text mb-0 text-muted">
                {{ problems.length }}
                {{ "problem" | pluralize(problems.length) }}
              </p>
            </div>
            <modalImpactedServices :modalID="'HeaderProblems'" />
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" v-if="itemCount > 0">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-info">
                <i class="ti-list mx-0"></i>
              </div>
            </div>
            <div
              class="preview-item-content"
              @click="$bvModal.show('HeaderItemsAttention')"
            >
              <h6 class="preview-subject font-weight-normal mb-1">
                Items Need Attention
              </h6>
              <p class="font-weight-light small-text mb-0 text-muted">
                {{ itemCount }} {{ "item" | pluralize(itemCount) }}
              </p>
            </div>
            <modalItemsNeedingAttention :modalID="'HeaderItemsAttention'" />
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" v-if="!jobs.length && userprofile.type == 1">
            <div class="preview-item-content">
              <h6
                class="preview-subject font-weight-normal mb-1"
              >
                No jobs running
              </h6>
              <p class="font-weight-light small-text mb-0 text-muted">
              </p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" v-for="thisjob in jobs"
                :key="thisjob.id" @click="unwatchJob(thisjob.id)">
            <div class="preview-item-content">
              <h6
                class="preview-subject font-weight-normal mb-1"
              >
                Job {{ thisjob.id }} "{{ thisjob.name }}"
              </h6>
              <p class="font-weight-light small-text mb-0 text-muted">
                {{ thisjob.status }}
              </p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" v-if="!showbell" disabled>
            <div class="preview-item-content">
              <h6 class="preview-subject font-weight-normal mb-1">
                No notifications
              </h6>
            </div>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="nav-profile">
          <template slot="button-content">
            <span
              class="nav-link dropdown-toggle"
              id="profileDropdown"
              href="javascript:void(0);"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="nav-profile-img">
                <img
                  :src="'/' + this.$store.getters.getMyCustomerProfile.logo"
                  alt="image"
                />
              </div>
            </span>
          </template>
          <b-dropdown-item class="preview-item" disabled>
            {{ userprofile.firstname }} {{ userprofile.surname }}
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" @click="gotoRouter('/user/me')">
            <i class="ti-settings text-primary"></i> Profile
          </b-dropdown-item>
          <b-dropdown-item
            class="preview-item"
            v-if="userprofile.chameleon > 0 && allCustomerProfiles.length"
            v-b-modal.switchCustomer
          >
            <i class="ti-wand text-primary"></i> Switch Customer
          </b-dropdown-item>
          <b-modal
            id="switchCustomer"
            ref="switchCustomer"
            title="Select Customer to Impersonate:"
            size="md"
            @ok="apiSwitchCustomer(newCustomer.customerid, newTypeCode)"
          >
            <b-container fluid>
              <multiselect
                v-model="newCustomer"
                :multiple="false"
                label="customername"
                track-by="customername"
                :close-on-select="true"
                :clear-on-select="false"
                placeholder="Select Customer"
                :preserve-search="true"
                :options="customerList"
              >
                <template slot="tag">{{ "" }}</template>
              </multiselect>
              <b-form-select
                id="newTypeCodeid"
                text="(Choose)"
                v-model="newTypeCode"
              >
                <b-form-select-option
                  v-if="userprofile.chameleon == 1"
                  value="1"
                  >VERNet Admin
                </b-form-select-option>
                <b-form-select-option value="4"
                  >Customer Admin
                </b-form-select-option>
                <b-form-select-option value="5"
                  >Customer User
                </b-form-select-option>
              </b-form-select>
            </b-container>
            <div slot="modal-footer" class="w-100">
              <b-btn
                size="sm"
                class="float-right"
                variant="primary"
                @click="apiSwitchCustomer(newCustomer.customerid, newTypeCode)"
              >
                Swap to Customer
              </b-btn>

              <b-btn
                size="sm"
                class="float-left"
                variant="primary"
                @click="apiSwitchVERNet()"
                v-if="userprofile.chameleon === 1"
              >
                Revert to VERNet
              </b-btn>
            </div>
          </b-modal>
          <b-dropdown-item class="preview-item" v-b-modal.changePassword>
            <i class="fa fa-key text-primary"></i> Change Password
          </b-dropdown-item>
          <b-modal
            id="changePassword"
            ref="changePassword"
            title="Change Password"
            size="md"
            @ok="apiWritePassword()"
            @show="
              password1 = '';
              password2 = '';
            "
          >
            <b-container fluid>
              <label for="password1">Enter new password:</label>
              <b-form-input
                required
                id="password1"
                v-model="password1"
                type="password"
                class="mb-2"
              ></b-form-input>
              <label for="password2">Confirm new password:</label>
              <b-form-input
                required
                id="password2"
                v-model="password2"
                type="password"
                :state="password2 === password1"
              ></b-form-input>
              <b-form-invalid-feedback id="password2-feedback">
                Passwords do not match
              </b-form-invalid-feedback>
            </b-container>
          </b-modal>
          <b-dropdown-item class="preview-item" @click.once="userLogout()">
            <i class="ti-power-off text-primary"></i> Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <button
        class="navbar-toggler navbar-toggler-right align-self-center"
        type="button"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
//import axios from "axios";
import Snotify, { SnotifyPosition } from "vue-snotify";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import modalImpactedServices from "@/pages/events/components/modalImpactedServices";
import modalItemsNeedingAttention from "@/pages/users/components/modalItemsNeedingAttention";
import VueFuse from "vue-fuse";

const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const AUTHTOKEN_REQUEST = "AUTHTOKEN_REQUEST";
const USERPROFILE_SWAP_CUSTOMER = "USERPROFILE_SWAP_CUSTOMER";
const USERPROFILE_UPDATE = "USERPROFILE_UPDATE";
const JOB_WATCH_REMOVE = "JOB_WATCH_REMOVE";
const EVENTS_REQUEST = "EVENTS_REQUEST";

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.leftTop,
  },
});

export default {
  name: "app-header",
  components: {
    Multiselect,
    modalImpactedServices,
    modalItemsNeedingAttention,
    VueFuse,
  },
  data: function () {
    return {
      switchAjaxRunning: false,
      prevResultCount: 0,
      searchParameter: "",
      newTypeCode: null,
      newCustomer: {},
      results: [],
      password1: null,
      password2: null,
      showResults: false,
      searchOptions: {
        keys: [
          {
            name: "sitenumber",
            weight: 2.5,
          },
          {
            name: "equipmentname",
            weight: 2.4,
          },
          {
            name: "ipaddress",
            weight: 2.4,
          },
          {
            name: "sitename",
            weight: 2.4,
          },
          {
            name: "firstname",
            weight: 2.3,
          },
          {
            name: "surname",
            weight: 2.3,
          },
          {
            name: "email",
            weight: 2.3,
          },
          {
            name: "contactname",
            weight: 2.2,
          },
          {
            name: "serviceid",
            weight: 2,
          },
          {
            name: "description",
            weight: 1.5,
          },
          {
            name: "aendsitename",
            weight: 1,
          },
          {
            name: "bendsitename",
            weight: 1,
          },
          {
            name: "street",
            weight: 0.9,
          },
          {
            name: "suburb",
            weight: 0.8,
          },
          {
            name: "number",
            weight: 1,
          },
          {
            name: "title",
            weight: 2.2,
          },
        ],
        includeScore: true,
        includeMatches: true,
        minMatchCharLength: 3,
        shouldSort: true,
        threshold: 0.4,
        eventPoller: null,
      },
    };
  },
  filters: {
    eventType: function (value) {
      if (value == "1") {
        return "Scheduled Outage";
      } else if (value == "2") {
        return "Scheduled Hazard";
      } else if (value == "3") {
        return "Ongoing Network Outage";
      }
    },
  },
  beforeMount() {
    this.eventPoller = setInterval(
      function () {
        this.$store.commit("suppressLoading", "events");
        this.$store
          .dispatch(EVENTS_REQUEST)
          .then(() => {
            this.$store.commit("unsuppressLoading", "events");
          })
          .catch((resp) => {
            this.$store.commit("unsuppressLoading", "events");
            Vue.prototype.$snotify.warning(
              resp.message
                ? resp.message
                : "An error occurred retrieving the latest network events",
              {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
              }
            );
          });
      }.bind(this),
      120000
    );
    this.newTypeCode = this.userprofile.type;
    this.newCustomer.customerid = this.userprofile.customerid;
    this.newCustomer.customername =
      this.$store.getters.getMyCustomerProfile.customername;
  },
  beforeDestroy() {
    clearInterval(this.eventPoller);
  },
  computed: {
    searchPossibilities() {
      let searchResults = []

      //Check if each store is loaded before populating in search results

      if(this.$store.getters.getSitesList){
        searchResults = searchResults.concat(this.$store.getters.getSitesList)
      }

      if(this.$store.getters.getServicesList){
        searchResults = searchResults.concat(this.$store.getters.getServicesList)
      }

      if(this.$store.getters.getUsers){
        searchResults = searchResults.concat(this.$store.getters.getUsers)
      }

      if(this.$store.getters.getContacts){
        searchResults = searchResults.concat(this.$store.getters.getContacts)
      }

      if(this.$store.getters.getEventsList){
        searchResults = searchResults.concat(this.$store.getters.getEventsList)
      }

      if(this.$store.getters.getEquipment){
        searchResults = searchResults.concat(this.$store.getters.getEquipment)
      }

      return searchResults
    },
    userprofile() {
      return this.$store.getters.getProfile;
    },
    jobs() {
      return this.$store.getters.getWatchedJobs;
    },
    allCustomerProfiles() {
      return this.$store.getters.getActiveCustomerProfiles;
    },
    customerList() {
      var getCustomers = this.$store.getters.getActiveCustomerProfiles;
      var customerIDs = [];

      for (let i = 0; i < getCustomers.length; i++) {
        var thisCustomer = {};
        thisCustomer.customerid = getCustomers[i].customerid;
        thisCustomer.customername = getCustomers[i].code + " - " + getCustomers[i].customername;
        customerIDs.push(thisCustomer);
      }

      return customerIDs;
    },
    problems() {
      try {
        return this.$store.getters.getEventsListByType(3);
      } catch (error) {
        return [];
      }
    },
    attentionitems() {
      try {
        return this.$store.getters.getItems;
      } catch (error) {
        return [];
      }
    },
    itemCount() {
      return this.$store.getters.getItems.length;
    },
    showbell() {
      try {
        const events = this.$store.getters.getEventsListByType(3);
        const activeEvents = events.filter(
          (event) => event.endtime === null || event.endtime === 0
        );
        const hasItems =
          this.$store.getters.getItems.sites.length > 0 ||
          this.$store.getters.getItems.contacts.length > 0 ||
          this.$store.getters.getItems.notifications.length > 0 ||
          this.$store.getters.getItems.users.length > 0 ||
          this.jobs.length > 0;
        return activeEvents.length > 0 || hasItems;
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    unwatchJob(jobid) {
      this.$store.dispatch(JOB_WATCH_REMOVE, jobid);
    },
    apiWritePassword() {
      if (this.password1 === this.password2) {
        let tempProfile = this.userprofile;
        tempProfile.password = this.password1;
        this.$store
          .dispatch(USERPROFILE_UPDATE, tempProfile)
          .then(() => {
            this.$snotify.success("User password updated!", {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              editEnabled: false,
            });
            this.$store.dispatch(AUTHTOKEN_REQUEST, {
              username: this.userprofile.email,
              password: this.password1,
            });
          })
          .catch((resp) => {
            Vue.prototype.$snotify.warning(
              resp.message
                ? resp.message
                : "An error occurred while swapping between customers",
              {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                editEnabled: false,
              }
            );
          });
      } else {
        Vue.prototype.$snotify.warning("Passwords must match!", {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          editEnabled: false,
        });
      }
    },
    userColor(userType) {
      if (userType == 4) {
        return "greeney";
      } else if (userType == 5) {
        return "orangey";
      } else {
        return "info";
      }
    },
    gotoRouter(routerlink) {
      this.showResults = false;
      this.$router.push(routerlink);
    },
    servicetype(theType) {
      return this.$serviceTypes.find((service) => service.letter === theType);
    },
    eventColour: function (value) {
      if (value == "1") {
        return "warning";
      } else if (value == "2") {
        return "info";
      } else if (value == "3") {
        return "danger";
      }
    },
    getSiteStatus(theSite) {
      let affectedServices = 0;
      this.$store.getters
        .getServicesListBySite(theSite)
        .forEach((serviceelement) => {
          if (
            this.$store.getters.getCurrentEventsListBySID(
              serviceelement.serviceid
            ).length > 0
          ) {
            //Checks if each service is in any events, adds to the count if it finds a matching event.
            affectedServices++;
          }
        });

      if (affectedServices === 0) {
        return "online";
      } else if (
        affectedServices > 0 &&
        affectedServices <
          this.$store.getters.getServicesListBySite(theSite).length
      ) {
        return "impacted";
      } else {
        return "offline";
      }
    },
    handleResults(r) {
      this.results = r;
      if (r.length > 0 && r.length !== this.prevResultCount) {
        this.showResults = true;
      }

      this.prevResultCount = r.length;
    },
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    userLogout: function () {
      this.$store.dispatch(AUTHTOKEN_LOGOUT).then(() => {
        if (this.$route.path != '/login') {
            this.$router.push("/login");
        }
      })
    },
    searchBar() {
      this.showResults = false;

      if (this.searchParameter.trim()) {
        this.$router.push("/functions/search-result/" + this.searchParameter);
      }
    },
    apiSwitchCustomer(newItem, newType) {

      var customerid = newItem;
      var usertype = newType;
      this.$store
        .dispatch(USERPROFILE_SWAP_CUSTOMER, { customerid, usertype })
        .then(() => {
          this.$snotify.success("Changed Customers Successfully!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            editEnabled: false,
          });

          if(newType !== 1){ //Go to index to ensure the chameleon user doesn't wind up on a dead page.
            this.$router.push("/").then(() => {
              this.$router.go(); //Force reload to get new customer's information.
            })
          } else {
            this.$router.go();
          }
        
        })
        .catch((resp) => {
          Vue.prototype.$snotify.warning(
            resp.message
              ? resp.message
              : "An error occurred while swapping between customers",
            {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              editEnabled: false,
            }
          );
        });
    },
    apiSwitchVERNet() {

      let VERNetCustomer = this.$store.getters.getCustomerProfileByCode("VER");

      var customerid = VERNetCustomer.customerid;
      var usertype = 1;

      this.$store
        .dispatch(USERPROFILE_SWAP_CUSTOMER, { customerid, usertype })
        .then(() => {
          this.$snotify.success("Changed Customers Successfully!", {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            editEnabled: false,
          });
        //  this.$router.push("/"); //Go to index to ensure the chameleon user doesn't wind up on a dead page.
          this.$router.go(); //Force reload to get new customer's information.
        })
        .catch((resp) => {
          Vue.prototype.$snotify.warning(
            resp.message
              ? resp.message
              : "An error occurred while swapping between customers",
            {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              editEnabled: false,
            }
          );
        });
    },
  },
};
</script>

<style scoped></style>
