<template>
        <div  class="services-card card col-12 p-0" :class="activeCheck">
          <div class="row container-fluid services-card-row">
            <div v-if='statusCheck() && service.status == 0' class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-luminous'">
              <div class="content text-center">{{ servicetype(service.type).type }}</div> <!--If Service is disabled-->
            </div>
            <div v-else-if='statusCheck() && service.status == 2' class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-planning'">
              <div class="content text-center">{{ servicetype(service.type).type }}</div> <!--If Service is planned but not active yet-->
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-warning'" v-else-if="serviceStatus.state === 1 && !showColour">
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-warning'" v-else-if="serviceStatus.state === 2 && !showColour">
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-danger'" v-else-if="serviceStatus.state === 3 && !showColour">
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>
            <div class="col-xl-2 col-5 servicetype rounded-topleft rounded-bottomleft" v-bind:class="'bg-'+servicetype(service.type).color" v-else>
              <div class="content text-center">{{ servicetype(service.type).type }}</div>
            </div>

            <router-link :to="'/service/'+service.serviceid" class="services-card-link col-7 col-xl-4 serviceid p-2">
              <div class="content pl-xl-2">
                <h4 class="text-muted">{{ service.serviceid }}</h4>
                <div class="text-muted">{{ service.description }}</div>
                <span class="d-xl-none text-small">
                  <br>{{ service.aendsitename }} =&gt; {{ service.bendsitename }}
                </span>
              </div>
            </router-link>
            <router-link :to="'/site/'+service.aendsiteid" class="col-2 site container-fluid d-none d-xl-inline services-card-link">
              <div class="row sitenumber">
                <h4 class="text-muted">{{ service.aendsitenumber }}</h4>
              </div>
              <div class="row sitename text-muted">{{ service.aendsitename }}</div>
            </router-link>
            
            <router-link :to="'/vpls/'+service.bendsiteid" v-if="service.type == 'F' || service.type == 'W'" class="col-2 site container-fluid d-none d-xl-inline services-card-link">
              <div class="row sitenumber">
                <h4 class="text-muted">{{ service.bendsitenumber }}</h4>
              </div>
              <div class="row sitename text-muted">{{ service.bendsitename }}</div>
            </router-link>
            <router-link :to="'/site/'+service.bendsiteid" v-else class="col-2 site container-fluid d-none d-xl-inline services-card-link">
              <div class="row sitenumber">
                <h4 class="text-muted">{{ service.bendsitenumber }}</h4>
              </div>
              <div class="row sitename text-muted">{{ service.bendsitename }}</div>
            </router-link>

            <router-link :to="'/event/'+serviceStatus.events[0].eventid" class=" col-2 services-card-link site container-fluid d-none d-xl-inline" v-if="serviceStatus.state > 0">
              <div class="row sitenumber">
                <h4 class="text-muted">Status</h4>
              </div>
              <div class="row sitename text-warning" v-if="serviceStatus.state == 1">
                Undergoing Scheduled Works #{{ serviceStatus.events[0].number }}
              </div>
              <div class="row sitename text-info" v-else-if="serviceStatus.state == 2">
                Within Hazard Window #{{ serviceStatus.events[0].number }}
              </div>
              <div class="row sitename text-danger" v-else-if="serviceStatus.state == 3">
                Affected by Network Outage #{{ serviceStatus.events[0].number }}
              </div>
            </router-link>

            <div class="col-2 site container-fluid d-none d-xl-inline" v-else>
              <div class="row sitenumber">
                <h4 class="text-muted">Status</h4>
              </div> 
              <div class="row sitename text-luminous" v-if = "statusCheck() && service.status == 0"> <!--Check if service.status exists first for VERNet users as Customers don't see disabled services-->
                Disabled
              </div>
              <div class="row sitename text-success" v-else-if="statusCheck() && service.status == 1">
                Online
              </div>
              <div class="row sitename text-planning" v-else-if="statusCheck() && service.status == 2">
                In Planning
              </div>
              <div class="row sitename text-success" v-else-if="!statusCheck()"> <!--Customers don't see service status. if no events found, assume active-->
                Online
              </div>
              <div class="row sitename text-luminous" v-else>
                Disabled
              </div>

            </div>
          </div>
        </div>

</template>

<script>
    export default {
        name: 'serviceComponent',
        props: {
          serviceID: String,
          showColour: Boolean 
        },
        computed:{
          service(){
            try {
              return this.$store.getters.getService(this.serviceID);
            } 
            catch (error){
              return this.items;
            }
          },
          activeCheck(){
            if(this.service && Object.keys(this.service).includes("status")){
              if(this.service.status == 0){
                return "inactiveElement"
              } else{
                return "activeElement"
              }
            } else {
              return "activeElement"
            }
          },
        },
        data () {
          return {
            serviceStatus: {
              state: 0,
              events: []
            }
          }
        },
        beforeMount (){
          if(this.statusCheck()){
            if(this.service.status == 0){
              let disabledStatus = {
                state: 0,
                events: []
              }
              this.serviceStatus = disabledStatus
            }
          } else {
            this.serviceStatus =  this.$store.getters.getServiceStatus(this.serviceID)
          }
        },
        methods:{
          servicetype(theType) {
            return this.$serviceTypes.find(service => service.letter === theType);
          },
          statusCheck(){ //Check if service object has "status" in it
            if(this.service){
              return Object.keys(this.service).includes("status")
            } else {
              false
            }
            
          }
        }
   }
</script>