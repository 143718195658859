<template>
  <b-modal :id="modalID" :ref="modalID" title="Create New Site" hide-footer size="md">
    <form id="site-update" @submit.prevent="siteCreate()">
      <table class="table">
        <tbody>
            <tr class="row" >
              <th class="col-md-3 col-12">
                <label for="sitenumber" v-if="typeSelection.type !== 2">Site Number:</label>
                <label for="sitenumber" v-else>VPLS Number:</label>
              </th>
              <td class="col-md-9 col-12">
                <b-form-input id="sitenumber" type="number" min="0000" max="9999" required v-model="newData.sitenumber" placeholder="eg; 0000"></b-form-input>
              </td>
            </tr>
            <tr class="row">
              <th class="col-md-3 col-12"><label for="sitename">Site Name:</label></th>
              <td class="col-md-9 col-12">
                <b-form-input type="text" id="sitename" required v-model="newData.sitename" placeholder="eg; 'Bundoora Campus'"></b-form-input>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 2">
              <th class="col-md-3 col-12"><label for="sitename">VPLS Name:</label></th>
              <td class="col-md-9 col-12">
                {{ vplsName }}
              </td>
            </tr>
            <tr class="row">
              <th class="col-md-3 col-12"><label for="customer">Site Type:</label></th>
              <td class="col-md-9 col-12">
                  <multiselect v-model="typeSelection" :multiple="false" label="description" track-by="description"
                              :close-on-select="true" :clear-on-select="false" placeholder="Select Site Type"
                              :preserve-search="true" :options="siteTypes" id="description">
                  <template slot="tag">{{ '' }}</template>
                  </multiselect>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="latitude">GPS Lat:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-input type="text" id="latitude" v-model="newData.latitude" placeholder="eg; -37.846574"></b-form-input>
              </td>
            </tr>
            <tr class="row"  v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="longitude">GPS Long:</label></th>
              <td class="col-md-9 col-12">
                <b-form-input type="text" id="longitude" v-model="newData.longitude" placeholder="eg; 144.978501"></b-form-input>
              </td>
            </tr>
            <tr class="row">
              <th class="col-md-3 col-12"><label for="customer">Customer:</label></th>
              <td class="col-md-9 col-12">
                  <multiselect v-model="Customer" :multiple="false" label="customername" track-by="customername"
                              :close-on-select="true" :clear-on-select="false" placeholder="Select Customer"
                              :preserve-search="true" :options="customerList" id="customer">
                  <template slot="tag">{{ '' }}</template>
                  </multiselect>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1">
              <th class="col-md-3 col-12"><label for="parentsite">Parent Site:</label></th>
              <td class="col-md-9 col-12">
                  <multiselect v-model="parentSite" :multiple="false" label="sitename" track-by="sitename"
                              :close-on-select="true" :clear-on-select="false" placeholder="Select Customer"
                              :preserve-search="true" :options="siteList" id="parentsite">
                  <template slot="tag">{{ '' }}</template>
                  </multiselect>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="building">Building:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-input type="text" id="building" v-model="newData.building"></b-form-input>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="street">Street Address:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-input v-if="typeSelection.type == 1 || typeSelection.type == 5" type="text" id="street" required v-model="newData.street"></b-form-input>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="suburb">Suburb:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-input v-if="typeSelection.type == 1 || typeSelection.type == 5" type="text" id="suburb" required v-model="newData.suburb"></b-form-input>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="postcode">Post Code:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-input type="number" v-if="typeSelection.type == 1 || typeSelection.type == 5" id="postcode" required v-model="newData.postcode" min="0" max="9999" step="1"></b-form-input>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="accesshours">Access Hours:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-input type="text" v-if="typeSelection.type == 1 || typeSelection.type == 5" id="accesshours" v-model="newData.accesshours"></b-form-input>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="accessnotes">Access Notes:</label></th>
              <td class="col-md-9 col-12">
                  <b-form-textarea type="textarea" v-if="typeSelection.type == 1 || typeSelection.type == 5" id="accessnotes" v-model="newData.accessnotes" rows="3"
        max-rows="6"></b-form-textarea>
              </td>
            </tr>
            <tr class="row" v-if="typeSelection.type == 1 || typeSelection.type == 5">
              <th class="col-md-3 col-12"><label for="customer">Site Access Type:</label></th>
              <td class="col-md-9 col-12">
                  <multiselect v-model="siteAccessTypeSelection" :multiple="false" label="description" track-by="description"
                              :close-on-select="true" :clear-on-select="false" placeholder="Select Site Access Type"
                              :preserve-search="true" :options="siteAccessTypes" id="description">
                  <template slot="tag">{{ '' }}</template>
                  </multiselect>
              </td>
            </tr>
            <tr  class="row" v-if="typeSelection.type == 2">
              <th class="col-md-3 col-12">
                <label for="sitename">MAC Address <br>Limit:</label>
              </th>
              <td class="col-md-9 col-12">
                <b-form-input id="maclimit" type="number" min="0000" max="9999" required v-model="maclimit" placeholder="eg; 0000"/>
              </td>
            </tr>
            <tr  class="row" v-if="typeSelection.type == 2">
              <th class="col-md-3 col-12">
                <label for="sitename">MAC Flush:</label>
              </th>
              <td class="col-md-9 col-12">
                <switches label="" v-model="macFlushSelection" theme="bootstrap" color="primary" class="pl-2 pr-2" />
              </td>
            </tr>
            <tr  class="row" v-if="typeSelection.type == 2">
              <th class="col-md-3 col-12">
                <label for="sitename">Allowed VLANS:</label>
              </th>
              <td class="col-md-9 col-12">
                <multiselect v-model="allowedVLANSelection" :multiple="false" label="description" track-by="description"
                              :close-on-select="true" :clear-on-select="false" placeholder="AllowedVLANS"
                              :preserve-search="true" :options="allowedVLANS" id="allowedVLANS">
                  <template slot="tag">{{ '' }}</template>
                </multiselect>
              </td>
            </tr>
        </tbody>
      </table>
      <div class="row mt-1">
        <div class="col-12 text-right">
          <button class="btn btn-light text-right p-3 mr-2" @click="hideModal" type="reset"><i
              class="mdi-cancel mdi pr-2"></i>Cancel
          </button>
          <button class="btn btn-success text-right p-3" type="submit"><i class="icon-plus pr-2"></i>Add
          </button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Switches from 'vue-switches';
import Snotify, {SnotifyPosition} from 'vue-snotify'
import Vue from "vue"
import Multiselect from "vue-multiselect";

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.leftTop,
  }
})

const SITE_CREATE = 'SITE_CREATE'

export default {
  name: "sitecreationform",
  props: {
    modalID: String
  },
  components:
      {
        Multiselect,
        Switches
      },
  data() {
    return {
      newData: {status: 1},
      Type: "",
      Enabled: "",
      toggleUserType: true,
      Customer: {
        customerid: "",
        customername: "",
        customercode: "",
      },
      parentSite: {},
      typeSelection: {
        description: "",
        type: 0
      },
      allowedVLANSelection: {},
      allowedVLANS: [
        {
          description: "All",
          type: "all"
        },
        {
          description: "None",
          type: "none"
        },
      ],
      macFlushSelection: false,
      maclimit: 1024,
      macflush: 0,
      vlanID: 0,
      siteTypes: [
        {
          description: "Physical",
          type: 1
        },
        {
          description: "VPLS",
          type: 2
        },
        {
          description: "Internet",
          type: 3
        },
        {
          description: "L3VPN",
          type: 4
        },
        {
          description: "Interconnects",
          type: 5
        }
      ],
      siteAccessTypes: [
        {
          description: "No Notification Required",
          type: 0
        },
        {
          description: "Mandatory Email Notification",
          type: 1
        },
        {
          description: "Courtesy Email Notification",
          type: 2
        },
        {
          description: "External Means",
          type: 3
        },
        {
          description: "Access Form Required",
          type: 4
        },
        {
          description: "Other",
          type: 5
        }
      ],
      siteAccessTypeSelection: {
          description: "",
          type: 0
      },
    }
  },
  computed: {
    customerList() {
      var getCustomers = this.$store.getters.getAllCustomerProfiles
      var customerIDs = []

      for (let i = 0; i < getCustomers.length; i++) {
        var thisCustomer = {}
        thisCustomer.customerid = getCustomers[i].customerid
        thisCustomer.customername = getCustomers[i].code + " - " + getCustomers[i].customername
        thisCustomer.customercode = getCustomers[i].code
        customerIDs.push(thisCustomer)
      }

      return customerIDs
    },
    vplsName(){
      return this.Customer.customercode + this.newData.sitenumber
    },
    siteList() {
      var getSites = this.$store.getters.getSitesList
      var siteIDs = []
      for (let i = 0; i < getSites.length; i++) {
        var thisSite= {}
        thisSite.siteid = getSites[i].siteid
        thisSite.sitename = "(" + getSites[i].sitenumber + ") - " + getSites[i].sitename
        siteIDs.push(thisSite)
      }

      return siteIDs
    },
  },
  methods: {
    hideModal() {
      this.$refs[this.modalID].hide()
    },
    siteCreate() {
      this.newData.sitenumber = "S" + String(this.newData.sitenumber)
      this.newData.customerid = (this.Customer.customerid ? this.Customer.customerid : null)
      this.newData.parentsite = (this.parentSite.siteid ? this.parentSite.siteid : null)
      this.newData.sitetype = (this.typeSelection.type ? this.typeSelection.type : null)

      if(this.newData.sitetype == 1 || this.newData.sitetype == 5){
        this.newData.accesstype = (this.siteAccessTypeSelection.type ? this.siteAccessTypeSelection.type : 0)
      } else { //default value for non-physical site types.
        this.newData.accesstype = 0
      }

      if(this.newData.sitetype == 2){
        this.newData.vplsname = this.vplsName
        this.newData.maclimit = this.maclimit
        if(this.macFlushSelection){
          this.newData.macflush = 1
        } else {
          this.newData.macflush = 0
        }
       
      //  this.newData.vlanid = String(this.vlanID)
        this.newData.allowedvlans = this.allowedVLANSelection.type
      }      

      this.$emit('ajaxrunning')
      this.$store.dispatch(SITE_CREATE, this.newData).then(() => {
        this.$snotify.success("Site Created!", {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          editEnabled: false
        });
        this.$emit('ajaxcomplete')
        this.$emit('success')
        this.newData = {status: 1}
        this.$refs[this.modalID].hide()
      })
      .catch((resp) => {
        Vue.prototype.$snotify.warning((resp.message ? resp.message : "An error occurred while creating this site!"), {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
        this.$emit('ajaxcomplete')
      })

    }
  }
}
</script>