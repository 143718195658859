import moment from "moment";

const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";

const SERVICE_EVENTS_REQUEST = "SERVICE_EVENTS_REQUEST";
const SERVICE_EVENTS_SUCCESS = "SERVICE_EVENTS_SUCCESS";
const SERVICE_EVENTS_ERROR = "SERVICE_EVENTS_ERROR";
const EVENTS_REQUEST = "EVENTS_REQUEST";
const EVENTS_ERROR = "EVENTS_ERROR";
const EVENTS_SUCCESS = "EVENTS_SUCCESS";
const EVENT_REQUEST = "EVENT_REQUEST";
const EVENT_ERROR = "EVENT_ERROR";
const EVENT_SUCCESS = "EVENT_SUCCESS";
const EVENTS_PAST_REQUEST = "EVENTS_PAST_REQUEST";
const EVENTS_PAST_ERROR = "EVENTS_PAST_ERROR";
const EVENTS_PAST_SUCCESS = "EVENTS_PAST_SUCCESS";
const EVENT_COMMENTS_REQUEST = "EVENT_COMMENTS_REQUEST";
const EVENT_COMMENTS_ERROR = "EVENT_COMMENTS_ERROR";
const EVENT_COMMENTS_SUCCESS = "EVENT_COMMENTS_SUCCESS";
const EVENT_CREATE = "EVENT_CREATE";
const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
const EVENT_CREATE_ERROR = "EVENT_CREATE_ERROR";
const EVENT_UPDATE = "EVENT_UPDATE";
const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
const EVENT_UPDATE_ERROR = "EVENT_UPDATE_ERROR";
const EVENT_DELETE = "EVENT_DELETE";
const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";
const EVENT_DELETE_ERROR = "EVENT_DELETE_ERROR";
const EVENT_COMMENT_CREATE = "EVENT_COMMENT_CREATE";
const EVENT_COMMENT_ERROR = "EVENT_COMMENT_ERROR";
const EVENT_COMMENT_SUCCESS = "EVENT_COMMENT_SUCCESS";
import Vue from "vue";
import axios from 'axios';

const state = {status: "", events: []};

const getters = {
    getEventsList: state => (state.events === null ? [] : state.events),
    getEvent: (state) => (eventid) => {
        return (state.events === null ? {} : state.events.find(event => event.eventid === eventid))
    },
    getEventByNumber: (state) => (eventNumber) => {
        return (state.events === null ? {} : state.events.find(event => event.number === parseInt(eventNumber)))
    },
    getPastEvents: (state) => (eventStart, eventEnd) => {
        return (state.events === null ? [] : state.events.filter(event => event.starttime >= eventStart && event.starttime <= eventEnd)) //Get all events between the start and end timestamps provided
    },
    getAllCurrentEvents(state) { //Returns all events that have started but not yet finished
    //    window.console.log("getAllCurrentEvents reached!")
        if(state.events === null) {
            return [] }
        if (typeof state.events.filter == "function") {
            return state.events.filter(event => {
                return event.starttime < moment().unix() && (event.endtime > moment().unix() || !event.endtime);
            });
        } else {
            return [];
        }
    },
    getFutureEventsList() {
        return (state.events === null ? [] : state.events.filter(event => {
            return event.starttime > moment().unix();
        }))
    },
    isEventsListLoaded: state => !!state.events,
    getEventsListByType: (state) => (eventtype) =>{
        return (state.events === null ? [] : state.events.filter(event => {
                return event.type === eventtype
            }))
    },
    getFutureEventsListByType(state) {
        if(state.events === null) {return [] }
        if (typeof state.events.filter == "function") {
            return eventtype => state.events.filter(event => {
                return event.type === eventtype && event.starttime > moment().unix();
            });
        } else {
            return [];
        }
    },
    getFutureOngoingEventsListByType(state) {
        if(state.events === null) {
            return [] }
        if (typeof state.events.filter == "function") {
            return eventtype => state.events.filter(event => {
                return event.type === eventtype && (event.endtime > moment().unix() || !event.endtime);
            });
        } else {
            return [];
        }
    },
    getPastEventsListByType(state) {
        if(state.events === null) {return [] }
        if (typeof state.events.filter == "function") {
            return eventtype => state.events.filter(event => {
                return event.type === eventtype && event.starttime < moment().unix();
            }).sort((a,b) => b.starttime-a.starttime);
        } else {
            return [];
        }
    },
    getRecentEventsByType(state) { //Returns events that have finished, and started within the past week.
        if(state.events === null) {return [] }
        if (typeof state.events.filter == "function") {
            return eventtype => state.events.filter(event => {
                return event.type === eventtype && event.endtime < moment().unix() && ((event.endtime + 604800) > moment().unix());
            }).sort((a,b) => b.starttime-a.starttime);
        } else {
            return [];
        }
    },
    getCurrentEventsListBySID: (state)  => (theSID) =>{
        if(state.events === null) { return []}
        if (typeof state.events.filter == "function") {
            return state.events.filter(event => {
                return event.type == '3' && event.services.find(service => service === theSID) && (event.endtime == null || event.endtime > moment().unix()); // Event is an unscheduled outage, and either has no end time yet or the estimated end time is still in the future.
            });
        } else {
            return [];
        }
    },
    getAllEventsListBySID: (state)  => (theSID) =>{
        if(state.events === null) { return []}
        if (typeof state.events.filter == "function") {
            return state.events.filter(event => {
                return event.services.find(service => service === theSID) && (event.endtime == null || event.endtime > moment().unix()); // Event is an unscheduled outage, and either has no end time yet or the estimated end time is still in the future.
            });
        } else {
            return [];
        }
    },
    getAllSiteOutageEvents: (state)  => (theSID) =>{
        if(state.events === null) { return []}
        if (typeof state.events.filter == "function") {
            return state.events.filter(event => {
                return event.type == '3' && event.services.find(service => service === theSID) && (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())); // Event is an unscheduled outage, and either has no end time yet or the estimated end time is still in the future.
            });
        } else {
            return [];
        }
    },
    getAllSiteWorksEvents: (state)  => (theSID) =>{
        if(state.events === null) { return []}
        if (typeof state.events.filter == "function") {
            return state.events.filter(event => {
                return event.type == '1' && event.services.find(service => service === theSID) && (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())); // Event is an unscheduled outage, and either has no end time yet or the estimated end time is still in the future.
            });
        } else {
            return [];
        }
    },
    getAllSiteHazardEvents: (state)  => (theSID) =>{
        if(state.events === null) { return []}
        if (typeof state.events.filter == "function") {
            return state.events.filter(event => {
                return event.type == '2' && event.services.find(service => service === theSID) && (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())); // Event is an unscheduled outage, and either has no end time yet or the estimated end time is still in the future.
            });
        } else {
            return [];
        }
    },
    getEventSites: (state) => (eventID) => {
        let thisEvent = (state.events === null ? {} : state.events.find(event => event.eventid === eventID))
        let siteArray = []
    //    let siteIDArray = []
        if(thisEvent !== null){

            for (var x = 0; x < thisEvent.services.length; x++){ //Go through the services of the event       
                let thisService = this.$store.getters.getService(thisEvent.services[x])

                if(!siteArray.includes(thisService.aendsiteid)){
                    siteArray.push(thisService.aendsiteid)
                }
                if(!siteArray.includes(thisService.bendsiteid)){
                    siteArray.push(thisService.bendsiteid)
                }
            }
            //window.console.log("Site Array: " + JSON.stringify(siteArray))
            return siteArray

        } else {
            return null
        }
    },
    getEventServicesByType(state) {
        if(state.events === null) {return [] }
        if (typeof state.events.filter == "function") {
            return serviceType => state.events.filter(event => {
                return event.services.find(service => service.slice(-1) === serviceType);
            });
        } else {
            return [];
        }
    },
    getFutureEventsListBySID(state) {
        if(state.events === null) {return [] }
        if (typeof state.events.filter == "function") {
            return theSID => state.events.filter(event => {
                return event.type != '3' && event.services.find(service => service === theSID);
            });
        } else {
            return [];
        }
    },
    getServiceStatus: (state) => (serviceID) => {
        if(state.events === null) {
            let serviceObject = {}
            serviceObject.events = []
            serviceObject.state = 0
            return serviceObject 
        }
            
            let serviceObject = {}
/*             let serviceList = state.events.filter(event => { //Get every event that is an existing scheduled outage or hazard that's started but hasn't ended yet.
                return (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())) && event.services.find(service => service === serviceID);
                }
            );

               if(serviceList == 0){ //Service isn't present in any events, return blank object.
                serviceObject.events = []
                serviceObject.state = 0
                return serviceObject
            } */

            /* 
            window.console.log("Service: " + serviceID)
            window.console.log("Service List: " + JSON.stringify(serviceList))
            window.console.log("Service List: " + JSON.stringify()) */

            let serviceList = state.events.filter(event => { //Get every event that is an existing problem that's started but hasn't ended yet.
                return (event.services.find(service => service === serviceID));
                });
            

            let ProblemList = serviceList.filter(event => { //Get every event that is an existing problem that's started but hasn't ended yet.
                return (event.type == 3 && (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())));
                }
            );

            if(ProblemList.length > 0){
                serviceObject.events = ProblemList
                serviceObject.state = 3
                return serviceObject //Service is experiencing an Unscheduled Outage, we don't care about other events right now.
            }

            let ScheduledList = serviceList.filter(event => { //Get every event that is an existing scheduled outage that's started but hasn't ended yet.
                return ((event.type == 1 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
                }
            );

            if(ScheduledList.length > 0){
                serviceObject.events = ScheduledList
                serviceObject.state = 1
                return serviceObject //Service is in a Scheduled Outage Window, almost definitely down at present, don't care about Hazards.
            }

            let HazardList = serviceList.filter(event => { //Get every event that is an existing scheduled hazard that's started but hasn't ended yet.
                return ((event.type == 2 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
                }
            );

            if(HazardList.length > 0){ 
                serviceObject.events = HazardList
                serviceObject.state = 2
                return serviceObject //Service is in a hazard window
            }
         

    //    window.console.log("Returned Problems: " + JSON.stringify(ProblemList))

            //Service is operating normally, return blank object
            serviceObject.events = []
            serviceObject.state = 0
            return serviceObject 
            
    //    return ProblemList.length > 0;
    },
    getSiteStatus: (state) => (siteServices, allEvents) => { //allEvents is optional BOOL to return all events relevant to the site.
        if(state.events === null) { //Events aren't loaded, return blank results
            let siteObject = {}
            siteObject.events = []
            siteObject.state = 0
            siteObject.serviceCount = 0
            return siteObject 
        }
        let siteObject = {}
        
        let eventList = state.events.filter(function(event) {
        //    return event.services.filter((e) => siteServices.includes(e.serviceid))
            let thisEvent = siteServices.filter(eService => event.services.includes(eService.serviceid));
            if(thisEvent.length !== 0){
            //    window.console.log("Test Value: " + JSON.stringify(testValue))
                return thisEvent
            } else {
                return false
            }
        })

        let ProblemList = eventList.filter(event => { //Get every event that is an existing problem that's started but hasn't ended yet.
            return (event.type == 3 && (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())));
            }
        );

        if(ProblemList.length > 0){
            let serviceCheck = ProblemList[0].services.filter(ProblemService => siteServices.some(service => service.serviceid === ProblemService))
        //    window.console.log("ServiceCheck: " + JSON.stringify(serviceCheck))
            if(serviceCheck.length == siteServices.length){
                siteObject.state = 6
            } else {
                siteObject.serviceCount = serviceCheck.length
                siteObject.state = 3
            }

        //    let serviceCheck = siteServices.filter(eService => eService.serviceid.includes(ProblemList.event))
            siteObject.serviceCount = serviceCheck.length
            siteObject.events = ProblemList

            if(allEvents){
                let ScheduledList = eventList.filter(event => { //Get every event that is an existing scheduled outage that's started but hasn't ended yet.
                    return ((event.type == 1 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
                    }
                );
                let HazardList = eventList.filter(event => { //Get every event that is an existing scheduled hazard that's started but hasn't ended yet.
                    return ((event.type == 2 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
                    }
                );
                
                siteObject.events = siteObject.events.concat(ScheduledList)
                siteObject.events = siteObject.events.concat(HazardList)    

            }
            return siteObject //Service is experiencing an Unscheduled Outage, we don't care about other events right now.
        }

        let ScheduledList = eventList.filter(event => { //Get every event that is an existing scheduled outage that's started but hasn't ended yet.
            return ((event.type == 1 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
            }
        );

        if(ScheduledList.length > 0){
            let serviceCheck = ScheduledList[0].services.filter(ScheduledService => siteServices.some(service => service.serviceid === ScheduledService))
            //    window.console.log("ServiceCheck: " + JSON.stringify(serviceCheck))
            if(serviceCheck.length == siteServices.length){
                siteObject.state = 5
            } else {
                siteObject.state = 1
            }

            siteObject.serviceCount = serviceCheck.length
            siteObject.events = ScheduledList


            if(allEvents){
                let HazardList = eventList.filter(event => { //Get every event that is an existing scheduled hazard that's started but hasn't ended yet.
                    return ((event.type == 2 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
                    }
                );
                
                siteObject.events = siteObject.events.concat(HazardList)    

            }

            return siteObject //Service is in a Scheduled Outage Window, almost definitely down at present, don't care about Hazards.
        }

        let HazardList = eventList.filter(event => { //Get every event that is an existing scheduled hazard that's started but hasn't ended yet.
            return ((event.type == 2 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
            }
        );

        if(HazardList.length > 0){
            let serviceCheck = HazardList[0].services.filter(HazardService => siteServices.some(service => service.serviceid === HazardService))
            //    window.console.log("ServiceCheck: " + JSON.stringify(serviceCheck))
            if(serviceCheck.length == siteServices.length){
                siteObject.state = 4
            } else {
                siteObject.state = 2
            }

            siteObject.serviceCount = serviceCheck.length
            siteObject.events = HazardList

            return siteObject //Service is in a hazard window
        }
     

//    window.console.log("Returned Problems: " + JSON.stringify(ProblemList))

        //Service is operating normally, return blank object
        siteObject.events = []
        siteObject.state = 0
        siteObject.serviceCount = 0
        return siteObject 










   //     let eventList = state.events.filter(event => siteServices.indexOf(event.services).serviceid > -1);
    //    window.console.log("Event List: " + JSON.stringify(eventList))

    //    return eventList
    },
    getVPLSStatus: (state) => (siteServices) => {
        if(state.events === null) { //Events aren't loaded, return blank results
            let siteObject = {}
            siteObject.events = []
            siteObject.state = 0
            siteObject.serviceCount = 0
            return siteObject 
        }
        let siteObject = {}
        
        let eventList = state.events.filter(function(event) {
        //    return event.services.filter((e) => siteServices.includes(e.serviceid))
            let thisEvent = siteServices.filter(eService => event.services.includes(eService.serviceid) && (eService.type == "F" || eService.type == "W"))
            if(thisEvent.length !== 0){
            //    window.console.log("Test Value: " + JSON.stringify(testValue))
                return thisEvent
            } else {
                return false
            }
        })

        let ProblemList = eventList.filter(event => { //Get every event that is an existing problem that's started but hasn't ended yet.
            return (event.type == 3 && (event.starttime < moment().unix() && (event.endtime == null || event.endtime > moment().unix())));
            }
        );

        if(ProblemList.length > 0){
            let serviceCheck = ProblemList[0].services.filter(ProblemService => siteServices.some(service => service.serviceid === ProblemService))
            if((serviceCheck.length == 1 && siteServices.length == 2) || (serviceCheck.length == (siteServices.length - 1))){ //Either one of two VPLS services are down, or all but one are down.  Either way, equates to entire VPLS being down.
                siteObject.state = 6
            } else if(serviceCheck.length == siteServices.length){
                siteObject.state = 6
            } else {
                siteObject.serviceCount = serviceCheck.length
                siteObject.state = 3
            }

        //    let serviceCheck = siteServices.filter(eService => eService.serviceid.includes(ProblemList.event))
            siteObject.serviceCount = serviceCheck.length
            siteObject.events = ProblemList

            return siteObject //Service is experiencing an Unscheduled Outage, we don't care about other events right now.
        }

        let ScheduledList = eventList.filter(event => { //Get every event that is an existing scheduled outage that's started but hasn't ended yet.
            return ((event.type == 1 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
            }
        );

        if(ScheduledList.length > 0){
            let serviceCheck = ScheduledList[0].services.filter(ScheduledService => siteServices.some(service => service.serviceid === ScheduledService))
            
            if((serviceCheck.length == 1 && siteServices.length == 2) || (serviceCheck.length == (siteServices.length - 1))){ //Either one of two VPLS services are down, or all but one are down.  Either way, equates to entire VPLS being down.
                siteObject.state = 5
            } else if(serviceCheck.length == siteServices.length){
                siteObject.state = 5
            } else {
                siteObject.state = 1
            }

            siteObject.serviceCount = serviceCheck.length
            siteObject.events = ScheduledList

            return siteObject //Service is in a Scheduled Outage Window, almost definitely down at present, don't care about Hazards.
        }

        let HazardList = eventList.filter(event => { //Get every event that is an existing scheduled hazard that's started but hasn't ended yet.
            return ((event.type == 2 && (event.starttime < moment().unix() && event.endtime > moment().unix())));
            }
        );

        if(HazardList.length > 0){
            let serviceCheck = HazardList[0].services.filter(HazardService => siteServices.some(service => service.serviceid === HazardService))
            
            if((serviceCheck.length == 1 && siteServices.length == 2) || (serviceCheck.length == (siteServices.length - 1))){ //Either one of two VPLS services are down, or all but one are down.  Either way, equates to entire VPLS being down.
                siteObject.state = 4
            } else if(serviceCheck.length == siteServices.length){
                siteObject.state = 4
            } else {
                siteObject.state = 2
            }

            siteObject.serviceCount = serviceCheck.length
            siteObject.events = HazardList

            return siteObject //Service is in a hazard window
        }
     

//    window.console.log("Returned Problems: " + JSON.stringify(ProblemList))

        //Service is operating normally, return blank object
        siteObject.events = []
        siteObject.state = 0
        siteObject.serviceCount = 0
        return siteObject 










   //     let eventList = state.events.filter(event => siteServices.indexOf(event.services).serviceid > -1);
    //    window.console.log("Event List: " + JSON.stringify(eventList))

    //    return eventList
    },
    getServiceStatusList: (state) => (serviceID) => {
        if(state.events === null) {return [] }
        var eventsList = state.events.filter(event => { //Get every event that is an existing problem, or a scheduled outage that's started but hasn't ended yet.
                return ((event.type === 3 || (event.starttime > Math.round(Date.now()/1000) && event.endtime < Math.round(Date.now()/1000))) && event.services.find(service => service === serviceID));
            }
        );

        if (eventsList.length > 0) {
            return eventsList //If there's anything in eventsList, the service is currently down
        } else {
            return []
        }
    },
    getImpactedServicesByType: (state) => (serviceType) => { //Gets impacted services for events affected by ongoing outages or works.
        if(state.events === null) {return [] }
            let eventsList = state.events.filter(event => {
        //    return event.type == 3 && event.services.find(service => service.slice(-1) === serviceType)  && (event.endtime > moment().unix() || !event.endtime);
            return (event.type == 3 && (event.endtime == null || event.endtime === 0 || event.endtime > moment().unix()) && event.services.find(service => service.slice(-1) === serviceType));
        });
        let serviceList = []

        for (var i = 0; i < eventsList.length; i++)  //For every currently-known ongoing event
        {
            var thisEvent = eventsList[i]
            //  serviceList = eventsList[i].filter(service => { return service.slice(-1) === Type })
            for (var x = 0; x < thisEvent.services.length; x++) //Go through the services of each event
            {
                var thisService = thisEvent.services[x]
                if (thisService.slice(-1) === serviceType) //If this service matches the type we want, push it into serviceList
                {
                    if (!serviceList.includes(thisService))  //If service is not already in the serviceList array
                    {
                        serviceList.push(thisService)
                    }
                }
            }
        }

        return serviceList  //Return all services of the matching type found
    },

    getRecentImpactedServicesByType: (state) => (serviceType) => {
        if(state.events === null) {return [] }
            let eventsList = state.events.filter(event => {
            return event.type == 3 && event.services.find(service => service.slice(-1) === serviceType) && event.endtime < moment().unix() && ((event.starttime + 604800) > (moment().unix() + 604800));
        });
        let serviceList = []

        for (var i = 0; i < eventsList.length; i++)  //For every currently-known ongoing event
        {
            var thisEvent = eventsList[i]
            //  serviceList = eventsList[i].filter(service => { return service.slice(-1) === Type })
            for (var x = 0; x < thisEvent.services.length; x++) //Go through the services of each event
            {
                var thisService = thisEvent.services[x]
                if (thisService.slice(-1) === serviceType) //If this service matches the type we want, push it into serviceList
                {
                    if (!serviceList.includes(thisService))  //If service is not already in the serviceList array
                    {
                        serviceList.push(thisService)
                    }
                }
            }
        }

        return serviceList  //Return all services of the matching type found
    },


    searchEvents: (state) => searchParam => {
        var searchTerm = new RegExp(searchParam, "i") //Events are searched by title, ticket number, description, and affected services
        return (state.events === null ? [] : state.events.filter((event => searchTerm.test(event.title) || searchTerm.test(event.number) || searchTerm.test(event.description) || searchTerm.test(event.services.filter(service => searchTerm.test(service))))))
    }
};

const actions = {
    [EVENTS_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(EVENTS_REQUEST);
            commit('addLoading', {handle: 'events', name: 'Load Events'})
            axios({url: Vue.prototype.$apiServer + 'events', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'events')
                    if (resp.data.result === "Success") {
                        commit(EVENTS_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENTS_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENTS_ERROR);
                    commit('removeLoading', 'events')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENT_REQUEST]: ({commit, dispatch}, eventid) => {
        return new Promise((resolve, reject) => {
            commit(EVENT_REQUEST);
            commit('addLoading', {handle: 'event'+eventid, name: 'Load Event'})
            axios({url: Vue.prototype.$apiServer + 'event/'+eventid, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'event'+eventid)
                    if (resp.data.result === "Success") {
                        commit(EVENT_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENT_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENT_ERROR);
                    commit('removeLoading', 'event'+eventid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENTS_PAST_REQUEST]: ({commit, dispatch}, reqParams) => {
        return new Promise((resolve, reject) => {
            commit(EVENTS_PAST_REQUEST);
            commit('addLoading', {handle: 'pastevents', name: 'Search Past Events'})
            axios({url: Vue.prototype.$apiServer + 'events/past/'+Math.round(reqParams.startdate)+'/'+Math.round(reqParams.enddate), method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'pastevents')
                    if (resp.data.result === "Success") {
                        commit(EVENTS_PAST_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENTS_PAST_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENTS_PAST_ERROR);
                    commit('removeLoading', 'pastevents')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENT_CREATE]: ({commit, dispatch}, payload) => {
    //    window.console.log("Payload: " + JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            commit(EVENT_CREATE);
            commit('addLoading', {handle: 'createevent', name: 'Creating Event ' + payload.number})
            axios({
            url: Vue.prototype.$apiServer + 'event', 
            method: "POST", 
            data: payload})
                .then(resp => {
                    commit('removeLoading', 'createevent')
                    if (resp.data.result === "Success") {
                        commit(EVENT_CREATE_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENT_CREATE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENTS_ERROR);
                    commit('removeLoading', 'createevent')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENT_UPDATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(EVENT_UPDATE);
            commit('addLoading', {handle: 'updateevent', name: 'Updating Event ' + payload.number})
            axios({
            url: Vue.prototype.$apiServer + 'event/' + payload.eventid, 
            method: "PUT", 
            data: payload})
                .then(resp => {
                    commit('removeLoading', 'updateevent')
                    if (resp.data.result === "Success") {
                        commit(EVENT_UPDATE_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENT_UPDATE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENTS_ERROR);
                    commit('removeLoading', 'updateevent')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENT_DELETE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(EVENT_DELETE);
            commit('addLoading', {handle: 'updateevent', name: 'Updating Event ' + payload.number})
            axios({
            url: Vue.prototype.$apiServer + 'event/' + payload.eventid, 
            method: "DELETE", 
            data: payload})
                .then(resp => {
                    //window.console.log("Payload Output: " + JSON.stringify(resp))
                    commit('removeLoading', 'updateevent')
                    if (resp.data.result === "Success") {
                        commit(EVENT_DELETE_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENT_DELETE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENTS_ERROR);
                    commit('removeLoading', 'updateevent')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENT_COMMENT_CREATE]: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            commit(EVENT_COMMENT_CREATE);
            commit('addLoading', {handle: 'eventcomment', name: 'Uploading comment'})
            axios({
            url: Vue.prototype.$apiServer + 'event/' + payload.eventid + "/comment", 
            method: "POST", 
            data: payload})
                .then(resp => {
                //    window.console.log("URL: " + Vue.prototype.$apiServer + 'event/' + payload.eventid + "/comment")
                //    window.console.log("Payload Output: " + JSON.stringify(resp))
                    commit('removeLoading', 'eventcomment')
                    if (resp.data.result === "Success") {
                        commit(EVENT_COMMENT_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(EVENT_COMMENT_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
               //     window.console.log("ERROR: " + JSON.stringify(err))
                    commit(EVENTS_ERROR);
                    commit('removeLoading', 'eventcomment')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [EVENT_COMMENTS_REQUEST]: ({commit, dispatch}, eventID) => {
        return new Promise((resolve, reject) => {
            commit(EVENT_COMMENTS_REQUEST);
            commit('addLoading', {handle: 'eventcomments', name: 'Load Event Comments'})
            axios({url: Vue.prototype.$apiServer + 'event/' + eventID, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'eventcomments')
                    if (resp.data.result === "Success") {
                        commit(EVENT_COMMENTS_SUCCESS, {"eventid": eventID, "response": resp.data.payload.comments});
                        resolve(resp.data)
                    } else {
                        commit(EVENT_COMMENTS_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(EVENT_COMMENTS_ERROR);
                    commit('removeLoading', 'eventcomments')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_EVENTS_REQUEST]: ({commit, dispatch}, serviceID) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_EVENTS_REQUEST);
            commit('addLoading', {handle: serviceID+'events', name: 'Load Service Events'})
            axios({url: Vue.prototype.$apiServer + 'events/'+serviceID, method: "GET"})
                .then(resp => {
                    commit('removeLoading', serviceID+'events')
                    if (resp.data.result === "Success") {
                        commit(SERVICE_EVENTS_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_EVENTS_ERROR)
                        reject(resp.data)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    commit(SERVICE_EVENTS_ERROR);
                    commit('removeLoading', serviceID+'events')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
};

const mutations = {
    [EVENTS_REQUEST]: state => {
        state.status = "loading";
    },
    [EVENTS_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "events", resp.payload);
    },
    [EVENTS_ERROR]: state => {
        state.status = "error";
    },
    [EVENT_CREATE]: state => {
        state.eventcreatestatus = "loading";
    },
    [EVENT_CREATE_SUCCESS]: state => {
        state.eventcreatestatus = "success";
    },
    [EVENT_CREATE_ERROR]: state => {
        state.eventcreatestatus = "error";
    },
    [EVENT_UPDATE]: state => {
        state.eventupdatestatus = "loading";
    },
    [EVENT_UPDATE_SUCCESS]: state => {
        state.eventupdatestatus = "success";
    },
    [EVENT_UPDATE_ERROR]: state => {
        state.eventupdatestatus = "error";
    },
    [EVENT_DELETE]: state => {
        state.eventdeletestatus = "loading";
    },
    [EVENT_DELETE_SUCCESS]: state => {
        state.eventdeletestatus = "success";
    },
    [EVENT_DELETE_ERROR]: state => {
        state.eventdeletestatus = "error";
    },
    [EVENT_COMMENT_CREATE]: state => {
        state.eventcommentstatus = "loading";
    },
    [EVENT_COMMENT_SUCCESS]: state => {
        state.eventcommentstatus = "success";
    },
    [EVENT_COMMENT_ERROR]: state => {
        state.eventcommentstatus = "error";
    },
    [EVENTS_PAST_REQUEST]: () => {

    },
    [EVENTS_PAST_SUCCESS]: () => {

    },
    [EVENTS_PAST_ERROR]: () => {

    },
    [EVENT_REQUEST]: () => {

    },
    [EVENT_SUCCESS]: () => {

    },
    [EVENT_ERROR]: () => {

    },
    [EVENT_COMMENTS_REQUEST]: state => {
        state.eventcommentstatus = "loading";
    },
    [EVENT_COMMENTS_SUCCESS]: (state) => {
        state.eventcommentstatus = "success";
            //Vue.set(state.events.find(event => event.eventid === payload.eventid), "comments", payload.response);
    },
    [EVENT_COMMENTS_ERROR]: state => {
        state.eventcommentstatus = "error";
    },
    [SERVICE_EVENTS_REQUEST]: () => {
        state.serviceeventsstatus = "loading"
    },
    [SERVICE_EVENTS_SUCCESS]: () => {
        state.serviceeventsstatus = "success"
    },
    [SERVICE_EVENTS_ERROR]: () => {
        state.serviceeventsstatus = "error"
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.events = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};