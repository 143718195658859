<template>
  <b-modal :id="modalID" :ref="modalID" title="Create New Service" hide-footer size="md">
    <form id="serviceadd" @submit.prevent="serviceAdd()">
      <div class="mb-3">
        <section>
            <div class="form-row">
              <label for="customer">Customer</label>
              <multiselect v-model="Customer" :multiple="false" required label="customername" track-by="customername"
                          :close-on-select="true" :clear-on-select="false" placeholder="Select Customer"
                          :preserve-search="true" :options="customerList" id="customer">
                <template slot="tag">{{ '' }}</template>
              </multiselect>
            </div>
            <div class="form-row mt-3">
              <label for="provisionDate">Availability Date</label>
              <b-form-datepicker type="date" id="provisionDate" required v-model="provisionDate"></b-form-datepicker>
            </div>
            <div class="form-row mt-3">
              <label for="serviceStateSelection">Service State:</label>
              <multiselect v-model="serviceStateSelection" required :multiple="false" label="description"
                          track-by="value"
                          :close-on-select="true" :clear-on-select="false" placeholder="Select Service State"
                          :preserve-search="true" :options="serviceState" id="serviceStateSelection"
                          >
                <template slot="tag">{{ '' }}</template>
              </multiselect>
            </div>
            <hr color="gray"/>       
            <div class="form-row mt-3">
              <label for="serviceTypeSelection">Service Diversity:</label>
              <div class="col-12 col-md-12 form-group">
                <multiselect v-model="serviceDiversity" :multiple="false" label="description" track-by="description"
                                :close-on-select="true" :clear-on-select="false" placeholder="Select Service Diversity Type"
                                :preserve-search="true" :options="serviceDiversitySelections" id="serviceDiversity">
                    <template slot="tag">{{ '' }}</template>
                </multiselect>
              </div>
            </div>
            <hr color="gray"/>
            <div class="form-row">
              <label for="siteA">Site A</label>
              <multiselect v-model="siteA" :multiple="false" required label="sitename" track-by="sitenumber"
                          :close-on-select="true" :clear-on-select="false" placeholder="Select Site A"
                          :preserve-search="true" :options="siteList" id="siteA">
                <template slot="tag">{{ '' }}</template>
              </multiselect>
            </div>
            <div class="form-row mt-3">
              <label for="siteB">Site B</label>
              <multiselect v-model="siteB" :multiple="false" required label="sitename" track-by="sitenumber"
                          :close-on-select="true" :clear-on-select="false" placeholder="Select Site B"
                          :preserve-search="true" :options="siteList" id="siteB">
                <template slot="tag">{{ '' }}</template>
              </multiselect>
            </div>
            <hr color="gray"/>
            <div class="form-row">
              <label for="serviceTypeSelection">Service Type:</label>
              <multiselect v-model="serviceTypeSelection" required :multiple="false" label="type"
                          track-by="letter"
                          :close-on-select="true" :clear-on-select="false" placeholder="Select Service Type"
                          :preserve-search="true" :options="serviceTypes" id="serviceTypeSelection"
                          @input="prefillSpeed()">
                <template slot="tag">{{ '' }}</template>
              </multiselect>
            </div>
            <div class="form-row mt-3">
              <label for="serviceSpeed">Speed: </label>
            </div>

            <div class="form-row">
              <b-form-input placeholder="eg; 200M or 1G" id="serviceSpeed"
                            v-model="serviceSpeed"></b-form-input>
            </div>
            <hr color="gray"/>
            <div class="form-row mt-3">
              <label for="serviceNumber">Sequence Number: </label>
            </div>

            <div class="form-row">
              <b-form-input id="serviceNumber" required type="number" min="0" max="99"
                            v-model="serviceNumber"></b-form-input>
            </div>
            <section v-if="serviceSearch.length>0 && Customer.code">
            <div class="form-row mt-3">
              Similar services:
            </div>
            <div class="form-row mt-2">
              <b-list-group class="w-100 text-center">
                  <b-list-group-item v-for="thisService in serviceSearch" :key="thisService.serviceid" variant="dark" class="py-2">
                  {{thisService.serviceid}}
                  </b-list-group-item>
              </b-list-group>
            </div>
            </section>
            <section v-if="Customer.code">
            <hr color="gray"/>
            <div class="form-row mt-3">
              <strong>Proposed Service ID:</strong>
            </div>
            <div class="form-row mt-2">
              <h4>{{serviceID}} <i :class="{'ml-2 icon-check text-success': validServiceID && !serviceCopyCheck, 'ml-2 icon-close text-danger': !validServiceID || serviceCopyCheck}"></i></h4>
            </div>
            <div class="form-row text-danger" v-if="serviceCopyCheck">
              Service already exists!
            </div>
            <div class="form-row mt-3">
              <strong>Service Description:</strong>
            </div>
            <div class="form-row mt-2">
              {{ siteDescription }}
            </div>

            <hr color="gray"/>
            <div class="form-row mt-3">
              <label for="serviceNumber">Service Notes: </label>
            </div>
            <div class="form-row">
              <b-form-textarea
                  id="textarea"
                  placeholder="Enter service notes"
                  rows="6"
                  v-model="serviceNotes"
              />
            </div>
            <hr color="gray"/>
            <div class="form-row mt-3">
              <label for="serviceNumber">Service Path: </label>
            </div>
            <div class="form-row">
              <b-form-textarea
                  id="textarea"
                  placeholder="Enter service path steps like S000>, NAME-S000-OM00-00-1-2-3>, or NAME-S000-MX00-00-ge-0/0/0>"
                  rows="6"
                  v-model="servicePath"
              />
            </div>
            </section>

            <hr color="gray"/>

          <div role="tablist" class="accordion pt-3">
          <b-card no-body class="col-12">
              <b-card-header header-tag="header" role="tab">
                <a href="javascript:void(0);" v-b-toggle="'HandoffDetails'">Equipment Handoffs (Optional)</a>
              </b-card-header>
              <b-collapse :id="'HandoffDetails'" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="form-col mb-4 col-12">
                    <button type="button" class="btn btn-primary btn-icon-text btn-sm" @click.prevent="getHandoffEquipment()">
                        <span>Identify Potential Handoff Interfaces</span>
                    </button>
                  </div>
                  <hr color="gray" />
                  <div class="form-row mb-3">
                    <label for="serviceTypeSelection">Handoff A:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="HandoffASelection" :multiple="false" label="name" track-by="uuid"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select Handoff Interface A"
                                      :preserve-search="true" :options="siteAHandoffs" id="siteAHandoffSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                    <div class="form-row col-12 form-group">
                      <b-form-input placeholder="Handoff A UUID" id="serviceDescription"
                                    v-model="HandoffASelection.uuid"></b-form-input>
                    </div>
                  </div>
                  <hr color="gray" />
                  <div class="form-row mb-3">
                    <label for="serviceTypeSelection">Handoff B:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="HandoffBSelection" :multiple="false" label="name" track-by="uuid"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select Handoff Interface B"
                                      :preserve-search="true" :options="siteBHandoffs" id="siteBHandoffSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                    <div class="form-row col-12 form-group">
                      <b-form-input placeholder="Handoff A UUID" id="serviceDescription"
                                    v-model="HandoffBSelection.uuid"></b-form-input>
                    </div>
                  </div>
                  <hr color="gray" v-if="siteB.sitetype == 2" />
                  <div class="form-row mb-3" v-if="siteB.sitetype == 2" >
                    <label for="serviceTypeSelection">Juniper Interface:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="juniperInterfaceSelection" :multiple="false" label="name" track-by="uuid"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select Juniper Interface"
                                      :preserve-search="true" :options="juniperHandoffs" id="juniperInterfaceSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                    <div class="form-row col-12 form-group">
                      <b-form-input placeholder="Juniper UUID" id="serviceDescription" v-model="juniperInterfaceSelection.uuid"/>               
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
        
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <a href="javascript:void(0);" v-b-toggle.servicePatching>Patching Details (Optional)</a>
              </b-card-header>
              <b-collapse id="servicePatching" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div class="form-row mt-3">
                    <label for="aendpanel">A-End Panel: </label>
                    <b-form-input placeholder="eg; VER_T30000_048" id="aendpanel" v-model="patching.aendpanel"/>
                  </div>
                  <div class="form-row mt-3">
                    <label for="aendport">A-End Port </label>
                    <b-form-input placeholder="eg; Ports 1,2" id="aendport" v-model="patching.aendport"/>
                  </div>
                  <div class="form-row mt-3">
                    <label for="serviceTypeSelection">A-End Port Connector Type:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="aendConnectorType" :multiple="false" label="description" track-by="description"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select connector type for Handoff A"
                                      :preserve-search="true" :options="ConnectorTypes" id="aendConnectorType">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <label for="serviceTypeSelection">A-End SFP:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="aendSFPSelection" :multiple="false" label="description" track-by="description"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select SFP for Handoff A"
                                      :preserve-search="true" :options="SFPTypes" id="aendSFPSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <label for="serviceTypeSelection">A-End Wavelength:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="aendWavelengthSelection" :multiple="false" label="description" track-by="description"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select wavelength for Handoff A"
                                      :preserve-search="true" :options="wavelengths" id="aendWavelengthSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                  </div>
                <div class="form-row mt-3">
                  <label for="serviceTypeSelection">SFP Description:</label>
                  <h4>{{aendSFPDescription}} </h4>
                </div>
                  <div class="form-row mt-3">
                    <label for="insertionlossa">A-End Insertion Loss </label>
                    <b-form-input placeholder="eg; Port 89 - 9 (average): 1310nm = 2.0dB, 1550nm = 1.3dB." id="insertionlossa" v-model="patching.insertionlossa"/>            
                  </div>
                  <hr color="gray"/>
                  <div class="form-row mt-3" v-if="handoffSiteA && serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                    <label for="aendpanel">A-End Patching Address: </label>
                    <b-form-input placeholder="A-ENd Address" id="aendAddress" v-model="patching.aendAddress"/>            
                  </div>
                  <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                    <label for="bendpanel">B-End Panel: </label>
                    <b-form-input placeholder="eg; VER_T30000_048" id="bendpanel" v-model="patching.bendpanel"/>
                  </div>
                  <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                    <label for="bendport">B-End Port </label>
                    <b-form-input placeholder="eg; Ports 1,2" id="bendport" v-model="patching.bendport"/>   
                  </div>
                  <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                    <label for="serviceTypeSelection">B-End Port Connector Type:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="bendConnectorType" :multiple="false" label="description" track-by="description"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select connector type for Handoff B"
                                      :preserve-search="true" :options="ConnectorTypes" id="bendConnectorType">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                  </div>
                   <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                    <label for="serviceTypeSelection">B-End SFP:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="bendSFPSelection" :multiple="false" label="description" track-by="description"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select SFP for Handoff B"
                                      :preserve-search="true" :options="SFPTypes" id="bendSFPSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                    <label for="serviceTypeSelection">B-End Wavelength:</label>
                    <div class="col-12 col-md-12 form-group">
                      <multiselect v-model="bendWavelengthSelection" :multiple="false" label="description" track-by="description"
                                      :close-on-select="true" :clear-on-select="false" placeholder="Select wavelength for Handoff B"
                                      :preserve-search="true" :options="wavelengths" id="bendWavelengthSelection">
                          <template slot="tag">{{ '' }}</template>
                      </multiselect>
                    </div>
                  </div>
                <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                  <label for="insertionlossb">SFP Description </label>
                  <h4>{{bendSFPDescription}} </h4>
                </div>
                <div class="form-row mt-3" v-if="serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                  <label for="insertionlossb">B-End Insertion Loss </label>
                  <b-form-input placeholder="eg; Port 90 - 10 (average): 1310nm = 2.0dB, 1550nm = 1.3dB." id="insertionlossb" v-model="patching.insertionlossb"/>            
                </div>
                <hr color="gray"/>
                <div class="form-row mt-3" v-if="handoffSiteB && serviceTypeSelection.letter !== 'F' && this.serviceTypeSelection.letter !== 'G' && this.serviceTypeSelection.letter !== 'H'">
                  <label for="bendpanel">B-End Patching Address: </label>
                  <b-form-input placeholder="B-End Address" id="bendAddress" v-model="patching.bendAddress"/>              
                </div>
                <hr color="gray"/>
                  <div class="form-row">
                    <label for="VLANnumber">VLAN</label>
                    <b-form-input id="vlan" type="number" min="0" max="9999" v-model="patching.vlan"/>  
                   <!--  <multiselect v-model="VLANnumber" :multiple="false" required label="sitename" track-by="sitenumber"
                                :close-on-select="true" :clear-on-select="false" placeholder="Select VLAN ID"
                                :preserve-search="true" :options="vlanSelections" id="VLANnumber">
                      <template slot="tag">{{ '' }}</template>
                    </multiselect> -->
                  </div>
                  <hr color="gray"/>
                  <div class="form-row mt-3">
                    <label for="ipaddress">IP Address</label>
                    <b-form-input placeholder="eg; xxx.xxx.xxx.xxx/xx" id="ipaddress" v-model="patching.ipaddress"/>  
                  </div>
                  <div class="form-row mt-3">
                    <label for="defaultgateway">Default Gateway</label>
                    <b-form-input placeholder="eg; xxx.xxx.xxx.xxx" id="defaultgateway" v-model="patching.defaultgateway"/>   
                  </div>
                  <hr color="gray"/>
                  <div class="form-row mt-3">
                    <label for="fibrelength">Fibre Length</label>
                    <b-form-input placeholder="eg; 4.1km" id="fibrelength" v-model="patching.fibrelength"/>  
                  </div>
                  <hr color="gray"/>
                  <div class="form-row mt-3">
                    <label for="patchingNotes">Patching Notes: </label>
                  </div>
                  <div class="form-row">
                      <b-form-textarea
                          id="textarea"
                          placeholder="Enter patching notes"
                          rows="6"
                          v-model="patching.notes"
                      />
                  </div>
                </b-card-body>
              </b-collapse> 
            </b-card>
          </div>
        </section>
        <div class="row mt-1">
          <div class="col-12 text-right">
            <button class="btn btn-light text-right p-3 mr-2" @click="hideModal" type="reset"><i
                class="mdi-cancel mdi pr-2"></i>Cancel
            </button>
            <button class="btn btn-success text-right p-3" type="submit"><i class="icon-plus pr-2"></i>Add
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Snotify, {SnotifyPosition} from 'vue-snotify'
import Vue from "vue"
import Multiselect from "vue-multiselect";

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.leftTop,
  }
})

const SERVICE_CREATE_REQUEST = 'SERVICE_CREATE_REQUEST'
const SERVICE_HANDOFF_REQUEST = 'SERVICE_HANDOFF_REQUEST'
const SERVICES_REQUEST = "SERVICES_REQUEST";

export default {
  name: "newServiceModal",
  props: {
    modalID: String
  },
  components:
      {
        Multiselect
      },
  data() {
    return {
      Type: "",
      Enabled: "",
      toggleUserType: true,
      Customer: {code : ''},
      siteA: {sitenumber: ''},
      siteB: {sitenumber: ''},
      serviceNotes: "",
      servicePath: "",
      aendSFPSelection: {
        description: ""
      },
      bendSFPSelection: {
        description: ""
      },
      aendWavelengthSelection: {
        description: ""
      },
      bendWavelengthSelection: {
        description: ""
      },
      aendConnectorType: {
        description: ""
      },
      bendConnectorType: {
        description: ""
      },
      provisionDate: "",
      serviceAcronym: "",
      sDescription: "",
      siteAHandoffs: [],
      siteBHandoffs: [],
      juniperHandoffs: [],
      HandoffASelection: {
        uuid: null,
        name: ""
      },
      HandoffBSelection: {
        uuid: null,
        name: ""
      },
      juniperInterfaceSelection: {
        uuid: null,
        name: ""
      },
      serviceDiversity: {
        description: "",
        diversity: null
      },
      SFPTypes: [
        { description: "1000Base-BX"},
        { description: "1000Base-LX"},
        { description: "1000Base-SX"},
        { description: "1000Base-T"},
        { description: "1000Base-ZX" },
        { description: "10GBase-ER"},
        { description: "10GBase-LR"},
        { description: "10GBase-ZR"},
        { description: "1G CWDM SFP"},
        { description: "10G CWDM SFP"},
        { description: "10G DWDM SFP"},
        { description: "FC800"},
        { description: "XFP"},
      ],
      ConnectorTypes: [
        { description: "SC"},
        { description: "SCA"},
        { description: "LC" },
        { description: "VLAN" },
      ],
      serviceDiversitySelections: [
        { description: "Primary", diversity: "Primary"},
        { description: "Backup", diversity: "Backup"},
        { description: "None", diversity: null },
      ],
      serviceStateSelection: {},
      serviceState: [
        { description: "Offline", value: 0 },
      //  { description: "Planned", value: 2 },
        { description: "Online", value: 1 },
      ],
      wavelengths: [
        { description: "850nm"},
        { description: "1310nm"},
        { description: "1470nm"},
        { description: "1490nm"},
        { description: "1510nm"},
        { description: "1550nm"},
        { description: "1570nm"},
        { description: "1580nm"},
        { description: "1590nm"},
        { description: "1610nm"},
      ],
      serviceNumber: 1,
      status: 1,
      serviceSpeed: '',
      serviceTypeSelection: {
        letter: ''
      },
      VLANnumber: {
        sitename: "",
        sitenumber: 0
      },
      serviceList: {},
      speed: "",
      description: "",
      patching: {
        aendpanel: "",
        bendpanel: "",
        aendsfp: null,
        bendsfp: null,
        juniperport: null,
        aendport: "",
        bendport: "",
        vlan: 0,
        ipaddress: "",
        defaultgateway: "",
        fibrelength: "",
        insertionlossa: "",
        insertionlossb: "",
        notes: "",
      }
    }
  },
  watch: {
    handoffSiteA(){
      this.patching.aendAddress = this.handoffSiteA.street + ", " +this.handoffSiteA.suburb + ", " +this.handoffSiteA.postcode
    },
    handoffSiteB(){
      if(this.serviceTypeSelection.letter !== "F" && this.serviceTypeSelection.letter !== "G" && this.serviceTypeSelection.letter !== "H"){
        this.patching.bendAddress = this.handoffSiteB.street + ", " +this.handoffSiteB.suburb + ", " +this.handoffSiteB.postcode
      }
    },
  },
  computed: {
    serviceSearch() {
      return this.$store.getters.searchServices(this.Customer.code + this.siteANumber + this.siteBNumber)
    },
    serviceTypes() {
      let serviceTypes = Vue.prototype.$serviceTypes
      let returnServiceTypes = []
      for (let i = 0; i < serviceTypes.length; i++) {
        let thisServiceType = {}
        if(serviceTypes[i].letter === "" || serviceTypes[i].letter === null){ 
          //We don't need the edge-cases, just the valid service types.
        } else{
          thisServiceType.type = serviceTypes[i].letter + " - " + serviceTypes[i].type
          thisServiceType.letter = serviceTypes[i].letter
          thisServiceType.color = serviceTypes[i].color
          returnServiceTypes.push(thisServiceType)
        }        
      }

      return returnServiceTypes
    },
    userprofile() {
      return this.$store.getters.getProfile;
    },
    aendSFPDescription() {
      if(this.aendSFPSelection.description !== ""){
        return this.aendSFPSelection.description + " (" + this.aendWavelengthSelection.description + ")"
      } else {
        return ""
      }
    },
    bendSFPDescription() {
      if(this.bendSFPSelection.description !== ""){
        return this.bendSFPSelection.description + " (" + this.bendWavelengthSelection.description + ")"
      } else {
        return ""
      }
    },
    handoffSiteA(){
      if(this.siteA){
        return this.$store.getters.getSite(this.siteA.siteid)
      }else{
        return null
      }
    },
    handoffSiteB(){
      if(this.siteB){
        return this.$store.getters.getSite(this.siteB.siteid)
      }else{
        return null
      }
    },
    siteANumber() {
      return this.siteA.sitenumber
    },
    siteBNumber() {
      return this.siteB.sitenumber
    },
    vlanSelections(){
      let getSites = this.$store.getters.getVLANSites
      let sitesList = []

      for (let i = 0; i < getSites.length; i++) {
        let thisSite = {sitenumber: '', sitename: ''}
        thisSite.sitenumber = parseInt(getSites[i].sitenumber.substring(1))
        thisSite.sitename = getSites[i].sitenumber + ": " + getSites[i].sitename
        sitesList.push(thisSite)
      }

      return sitesList
    },
    serviceID() {
      return this.Customer.code + this.siteANumber + this.siteBNumber + String(this.serviceNumber).padStart(2,'0') + this.serviceTypeSelection.letter
    },
    serviceCopyCheck() {
      let checkService = this.$store.getters.getService(this.serviceID)
      return !!checkService;
    },
    serviceCheck() {
      let serviceChecktest = /\w{3}\d{8}[a-zA-Z]{1}/
      return serviceChecktest.test(this.serviceID)
    },
    validServiceID() {
      return this.serviceCheck && parseInt(this.siteANumber) < parseInt(this.siteBNumber) && parseInt(this.siteANumber) > 0 && parseInt(this.siteBNumber) > 0 && parseInt(this.serviceNumber) > 0 && this.siteA !== null && this.siteB !== null;
    },
    customerList() {
      let getCustomers = this.$store.getters.getAllCustomerProfiles;
      let customerIDs = [];

      for (let i = 0; i < getCustomers.length; i++) {
        let thisCustomer = {};
        thisCustomer.customerid = getCustomers[i].customerid
        thisCustomer.customername = getCustomers[i].code + " - " + getCustomers[i].customername
        thisCustomer.code = getCustomers[i].code
        customerIDs.push(thisCustomer)
      }
      return customerIDs
    },
    siteList() {
    //  let getSites = this.$store.getters.getAllSitesByCustomerUUIDList(this.Customer.customerid)
    let getSites = this.$store.getters.getSitesList
      let sitesList = []

      for (let i = 0; i < getSites.length; i++) {
        let thisSite = {sitenumber: '', sitename: '', siteid: ''}
        thisSite.sitenumber = getSites[i].sitenumber.substring(1)
        thisSite.sitename = getSites[i].sitenumber + ": " + getSites[i].sitename
        thisSite.siteid = getSites[i].siteid
        thisSite.sitetype = getSites[i].sitetype
        sitesList.push(thisSite)
      }

      return sitesList
    },
    siteDescription() {
      return (this.siteA == null ? "Site A" : this.siteA.sitename) + " to " + (this.siteB == null ? "Site B" : this.siteB.sitename)
    },
    unixDate() {
      let dateValue = new Date(this.provisionDate);
      return dateValue.getTime() / 1000
    }
  },
  methods: {
    hideModal() {
      this.Type = "",
      this.Enabled = "",
      this.toggleUserType = true,
      this.Customer = { code: ''},
      this.provisionDate = "",
      this.serviceAcronym = "",
      this.siteA = {sitenumber: ''},
      this.siteB = {sitenumber: ''},
      this.serviceStateSelection = {},
      this.serviceNumber = 1,
      this.status = 1,
      this.serviceNotes = "",
      this.servicePath = "",
      this.serviceTypeSelection = {
        letter: ''
      },
      this.serviceList = {},
      this.serviceSpeed = '',
      this.description = "",
      this.siteAHandoffs = [],
      this.siteBHandoffs = [],
      this.juniperHandoffs = [],
      this.HandoffASelection = {
        uuid: null,
        name: ""
      },
      this.HandoffBSelection = {
        uuid: null,
        name: ""
      },
      this.juniperInterfaceSelection = {
        uuid: null,
        name: ""
      },
      this.patching = {
        aendpanel: "",
        bendpanel: "",
        aendsfp: "",
        bendsfp: "",
        aendport: null,
        bendport: null,
        juniperport: null,
        vlan: 0,
        ipaddress: "",
        defaultgateway: "",
        fibrelength: "",
        insertionlossa: "",
        insertionlossb: "",
        notes: ""
      }
      this.VLANnumber = {
        sitename: "",
        sitenumber: 0
      },
      this.aendSFPSelection = {
        description: ""
      },
      this.bendSFPSelection = {
        description: ""
      },
      this.aendWavelengthSelection = {
        description: ""
      },
      this.bendWavelengthSelection = {
        description: ""
      },
      this.aendConnectorType = {
        description: ""
      },
      this.bendConnectorType = {
        description: ""
      },
      this.$refs[this.modalID].hide()
    },
    prefillSpeed() {
      switch (this.serviceTypeSelection.letter) {
        case 'B':
          this.serviceSpeed = '1G'
          break;
        case 'D':
          this.serviceSpeed = '10G'
          break;
        default:
          break;
      }
    },
    getHandoffEquipment() {
      if (this.validServiceID) {
        this.siteAHandoffs = [],
        this.siteBHandoffs = [],
        this.$store.dispatch(SERVICE_HANDOFF_REQUEST, this.serviceID)
            .then(payload => {
              payload.payload.aConfident.forEach( thisPortA => {
                let possiblePortA = {}
                possiblePortA.name = thisPortA.port + " (Confident)"
                possiblePortA.uuid = thisPortA.uuid    
                this.siteAHandoffs.push(possiblePortA)
                this.juniperHandoffs.push(possiblePortA)
              });
              payload.payload.bConfident.forEach( thisPortB => {
                let possiblePortB = {}
                possiblePortB.name = thisPortB.port + " (Confident)"
                possiblePortB.uuid = thisPortB.uuid
                this.siteBHandoffs.push(possiblePortB)
                this.juniperHandoffs.push(possiblePortB)
              });
              payload.payload.aMaybe.forEach( thisPortA => {
                let possiblePortA = {}
                possiblePortA.name = thisPortA.port + " (Maybe)"
                possiblePortA.uuid = thisPortA.uuid
                this.siteAHandoffs.push(possiblePortA)
                this.juniperHandoffs.push(possiblePortA)
              });
              payload.payload.bMaybe.forEach( thisPortB=> {
                let possiblePortB = {}
                possiblePortB.name = thisPortB.port + " (Maybe)"
                possiblePortB.uuid = thisPortB.uuid
                this.siteBHandoffs.push(possiblePortB)
                this.juniperHandoffs.push(possiblePortB)
              });
              payload.payload.unsure.forEach( thisPortA => {
                let possiblePortA = {}
                possiblePortA.name = thisPortA.port + " (Unsure)"
                possiblePortA.uuid = thisPortA.uuid
                this.siteAHandoffs.push(possiblePortA)
                if(!this.juniperHandoffs.find(thisHandoff => thisHandoff.name == possiblePortA.name)){
                  this.juniperHandoffs.push(possiblePortA)
                }
              });
              payload.payload.unsure.forEach( thisPortB=> {
                let possiblePortB = {}
                possiblePortB.name = thisPortB.port + " (Unsure)"
                possiblePortB.uuid = thisPortB.uuid
                this.siteBHandoffs.push(possiblePortB)
                if(!this.juniperHandoffs.find(thisHandoff => thisHandoff.name == possiblePortB.name)){
                  this.juniperHandoffs.push(possiblePortB)
                }
              });
            }).catch(err => {
          Vue.prototype.$snotify.warning((err.message ? err.message : 'An error occurred while obtaining handoffs for this new service.'), {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
          });
        })
      } else {
        Vue.prototype.$snotify.warning("Service " + this.serviceID + " is not a valid service ID!", {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
      }
    },
    serviceAdd() {
      if (this.validServiceID && !this.serviceCopyCheck) {
        let payload = {
          serviceid: this.serviceID,
          customerid: this.Customer.customerid,
          speed: this.serviceSpeed,
          description: this.siteDescription,
          diversity: this.serviceDiversity.diversity,
          path: this.servicePath,
          notes: this.serviceNotes,
          status: this.serviceStateSelection.value,
          provisiondate: this.unixDate,
          aendsiteid: (this.siteA.siteid !== "" ? this.siteA.siteid : null), 
          bendsiteid: (this.siteB.siteid !== "" ? this.siteB.siteid : null), 
          patching: {
            aendaddress: (this.patching.aendAddress !== "" ? this.patching.aendAddress : null),
            aendpanel: (this.patching.aendpanel !== "" ? this.patching.aendpanel : null),
            aendconnector: (this.aendConnectorType.description  !== "" ? this.aendConnectorType.description  : null),
            aendsfp: (this.aendSFPSelection.description  !== "" ? this.aendSFPSelection.description  : null),
            aendport: (this.patching.aendport.length > 0 ? this.patching.aendport : null),
            aendwavelength: (this.aendWavelengthSelection.description !== "" ? this.aendWavelengthSelection.description : null),
            insertionlossa: (this.patching.insertionlossa !== "" ? this.patching.insertionlossa : null),
            vlan: (this.patching.vlan !== 0 ? this.patching.vlan : null),
            ipaddress: (this.patching.ipaddress !== "" ? this.patching.ipaddress : null),
            defaultgateway: (this.patching.defaultgateway !== "" ? this.patching.defaultgateway : null),
            fibrelength: (this.patching.fibrelength !== "" ? this.patching.fibrelength : null),
            notes: (this.patching.notes !== "" ? this.patching.notes : null)
          }
        }

        if(this.HandoffASelection.uuid !== null){
          payload.aendportid = this.HandoffASelection.uuid
        }

        if(this.HandoffBSelection.uuid !== null){
          payload.bendportid = this.HandoffBSelection.uuid
        }

        if(this.juniperInterfaceSelection.uuid !== null){
          payload.juniperportid = this.juniperInterfaceSelection.uuid
        }

        if(this.serviceTypeSelection.letter !== "F" && this.serviceTypeSelection.letter !== "G" && this.serviceTypeSelection.letter !== "H") {
          payload.patching.bendaddress = (this.patching.bendAddress !== "" ? this.patching.bendAddress : null),
          payload.patching.bendpanel = (this.patching.bendpanel !== "" ? this.patching.bendpanel : null)
          payload.patching.bendconnector = (this.bendConnectorType.description  !== "" ? this.bendConnectorType.description  : null)
          payload.patching.bendsfp = (this.bendSFPSelection.description  !== "" ? this.bendSFPSelection.description  : null)
          payload.patching.bendport = (this.patching.bendport.length > 0 ? this.patching.bendport : null)
          payload.patching.bendwavelength = (this.bendWavelengthSelection.description !== "" ? this.bendWavelengthSelection.description : null)
          payload.patching.insertionlossb = (this.patching.insertionlossb !== "" ? this.patching.insertionlossb : null)
        } else {
          payload.patching.bendaddress = this.siteB.sitename
        }

      //  window.console.log("Service: " + JSON.stringify(payload))

        this.$store.dispatch(SERVICE_CREATE_REQUEST, payload)
            .then(() => {
              Vue.prototype.$snotify.success("Service " + this.serviceID + " created!", {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
              });
              this.$store.dispatch(SERVICES_REQUEST);
              this.hideModal()
            })
            .catch(err => {
              Vue.prototype.$snotify.warning((err.message ? err.message : 'An error occurred while creating this service. Please try again.'), {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
              });
            })
      } else {
        Vue.prototype.$snotify.warning("Service ID is not valid or in use", {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
      }
    }
  }
}
</script>