//import store from "../index";

const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const JOBSTATUS_REQUEST = "JOBSTATUS_REQUEST";
const JOBSTATUS_ERROR = "JOBSTATUS_ERROR";
const JOBSTATUS_SUCCESS = "JOBSTATUS_SUCCESS";
const JOB_WATCH_ADD = "JOB_WATCH_ADD";
const JOB_WATCH_REMOVE = "JOB_WATCH_REMOVE";

import Vue from "vue";
import axios from "axios";

const state = { status: "", jobs: [], watchjobs: [], running: false };

const getters = {
  getJobs: (state) => state.jobs,
  //getWatchedJobs: (state) => state.jobs.filter((job) => job.id === state.watchjobs.includes(job.id)),
  getWatchedJobs: (state) => state.jobs.filter((job) => state.watchjobs.includes(job.id)),
};

const actions = {
  [JOBSTATUS_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(JOBSTATUS_REQUEST);
      axios({ url: Vue.prototype.$apiServer + "awx/jobs", method: "GET" })
        .then((resp) => {
          if (resp.data.result === "Success") {
            commit(JOBSTATUS_SUCCESS, resp.data);
            resolve(resp.data);
          } else {
            commit(JOBSTATUS_ERROR);
            reject(resp.data);
          }
          resolve(resp);
        })
        .catch((err) => {
          commit(JOBSTATUS_ERROR);
          // if resp is unauthorized, logout, to
          if (err.response.status === 403) {
            dispatch(AUTHTOKEN_LOGOUT);
          }
          reject(err.response.data);
        });
    });
  },
  [JOB_WATCH_ADD]: ({ commit, dispatch, state }, jobid) => {
    return new Promise((resolve) => {
      commit(JOB_WATCH_ADD, jobid);
      dispatch(JOBSTATUS_REQUEST);
      if (state.watchjobs.length > 0 && !state.running) {
        state.running = true;
        state.interval = setInterval(() => {
          dispatch(JOBSTATUS_REQUEST);
        }, 10000);
      }
      resolve();
    });
  },
  [JOB_WATCH_REMOVE]: ({ commit }, jobid) => {
    return new Promise((resolve) => {
      commit(JOB_WATCH_REMOVE, jobid);
      resolve();
    });
  },
};

const mutations = {
  [JOBSTATUS_REQUEST]: (state) => {
    state.status = "loading";
  },
  [JOBSTATUS_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "jobs", resp.payload);
    if(state.jobs.filter((job) => job.id === state.watchjobs.includes(job.id)).filter((job) => job.ended === null).length) {
      state.running = false;
      clearInterval(state.interval);
    }
  },
  [JOBSTATUS_ERROR]: (state) => {
    state.status = "error";
  },
  [JOB_WATCH_ADD]: (state, jobid) => {
    state.watchjobs.push(jobid);
    state.watchjobs = [...new Set(state.watchjobs)];
  },
  [JOB_WATCH_REMOVE]: (state, jobid) => {
    state.watchjobs = state.watchjobs.filter((job) => job !== jobid);
    if (state.watchjobs.length === 0) {
      state.running = false;
      //Cancel the timer we started in JOB_WATCH_ADD
      clearInterval(state.interval);
    }
  },
  [AUTHTOKEN_LOGOUT]: (state) => {
    state.jobs = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
