const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const SERVICES_REQUEST = "SERVICES_REQUEST";
const SERVICES_ERROR = "SERVICES_ERROR";
const SERVICES_SUCCESS = "SERVICES_SUCCESS";
const SERVICE_REQUEST = "SERVICE_REQUEST";
const SERVICE_ERROR = "SERVICE_ERROR";
const SERVICE_SUCCESS = "SERVICE_SUCCESS";
const SERVICE_MACTABLE_REQUEST = "SERVICE_MACTABLE_REQUEST";
const SERVICE_MACTABLE_ERROR = "SERVICE_MACTABLE_ERROR";
const SERVICE_MACTABLE_SUCCESS = "SERVICE_MACTABLE_SUCCESS";
const SERVICE_L1_REQUEST = "SERVICE_L1_REQUEST";
const SERVICE_L1_ERROR = "SERVICE_L1_ERROR";
const SERVICE_L1_SUCCESS = "SERVICE_L1_SUCCESS";
const SERVICE_LIGHT_REQUEST = "SERVICE_LIGHT_REQUEST";
const SERVICE_LIGHT_ERROR = "SERVICE_LIGHT_ERROR";
const SERVICE_LIGHT_SUCCESS = "SERVICE_LIGHT_SUCCESS";
const SERVICE_TRAFFIC_REQUEST = "SERVICE_TRAFFIC_REQUEST";
const SERVICE_TRAFFIC_ERROR = "SERVICE_TRAFFIC_ERROR";
const SERVICE_TRAFFIC_SUCCESS = "SERVICE_TRAFFIC_SUCCESS";
const SERVICE_LIGHTHISTORY_REQUEST = "SERVICE_LIGHTHISTORY_REQUEST";
const SERVICE_LIGHTHISTORY_ERROR = "SERVICE_LIGHTHISTORY_ERROR";
const SERVICE_LIGHTHISTORY_SUCCESS = "SERVICE_LIGHTHISTORY_SUCCESS";
const SERVICE_TRAFFICHISTORY_REQUEST = "SERVICE_TRAFFICHISTORY_REQUEST";
const SERVICE_TRAFFICHISTORY_ERROR = "SERVICE_TRAFFICHISTORY_ERROR";
const SERVICE_TRAFFICHISTORY_SUCCESS = "SERVICE_TRAFFICHISTORY_SUCCESS";
const SERVICE_OPERATIONALHISTORY_REQUEST = "SERVICE_OPERATIONALHISTORY_REQUEST";
const SERVICE_OPERATIONALHISTORY_SUCCESS = "SERVICE_OPERATIONALHISTORY_SUCCESS";
const SERVICE_OPERATIONALHISTORY_ERROR = "SERVICE_OPERATIONALHISTORY_ERROR";
const SERVICE_LINKSTATUS_REQUEST = "SERVICE_LINKSTATUS_REQUEST";
const SERVICE_LINKSTATUS_ERROR = "SERVICE_LINKSTATUS_ERROR";
const SERVICE_LINKSTATUS_SUCCESS = "SERVICE_LINKSTATUS_SUCCESS";
const SERVICE_UPDATE_REQUEST = "SERVICE_UPDATE_REQUEST";
const SERVICE_UPDATE_ERROR = "SERVICE_UPDATE_ERROR";
const SERVICE_UPDATE_SUCCESS = "SERVICE_UPDATE_SUCCESS";
const SERVICE_CREATE_REQUEST = "SERVICE_CREATE_REQUEST";
const SERVICE_CREATE_ERROR = "SERVICE_CREATE_ERROR";
const SERVICE_CREATE_SUCCESS = "SERVICE_CREATE_SUCCESS";
const SERVICE_HANDOFF_REQUEST = "SERVICE_HANDOFF_REQUEST";
const SERVICE_HANDOFF_SUCCESS = "SERVICE_HANDOFF_SUCCESS";
const SERVICE_HANDOFF_ERROR = "SERVICE_HANDOFF_ERROR";


import Vue from "vue";
import axios from 'axios';

const state = {status: "", services: []};

const getters = {
    getServicesStatus: state => state.status,
    getServicesList: state => (state.services===null ? [] : state.services),
    getAllServices: (state) => {
        return (state.services === null ? [] : state.services)
    },
    getService: (state) => (serviceid) => {
        if(state.services === null) {return {} }
        return state.services.find(service => service.serviceid === serviceid)
    },
    isServicesListLoaded: state => !!state.services,
    getServicesListByType: (state) => (servicetype) => {
        return (state.services === null ? [] : state.services.filter(service => {
                return service.type === servicetype
            }))
    },
    getServicesListBySite: (state) => siteuuid => {
        let searchTerm = new RegExp(siteuuid, "i")
        return (state.services === null ? [] : state.services.filter((service => searchTerm.test(service.aendsiteid) || searchTerm.test(service.bendsiteid))))
    },
    getServicesListByCustomerID: (state) => customeruuid => {
        return (state.services === null ? [] : state.services.filter((service => service.customerid===customeruuid)))
    },
    searchServices: (state) => searchParam => {
        let searchTerm = new RegExp(searchParam, "i") //Services are searched by Service ID, Handoff A's Name or Site Number, Handoff B's Name or Site Number
        return (state.services === null ? [] : state.services.filter((service => searchTerm.test(service.serviceid) ||searchTerm.test(service.description) || searchTerm.test(service.aendsitenumber) || searchTerm.test(service.aendsitename) || searchTerm.test(service.bendsitenumber) || searchTerm.test(service.bendsitename))))
    }
};

const actions = {
    [SERVICES_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(SERVICES_REQUEST);
            commit('addLoading', {handle: 'services', name: 'Load Services'})
            axios({url: Vue.prototype.$apiServer + 'services', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'services')
                    if (resp.data.result === 'Success') {
                        commit(SERVICES_SUCCESS, resp.data)
                        resolve(resp.data)
                    } else {
                        commit(SERVICES_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICES_ERROR);
                    commit('removeLoading', 'services')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_REQUEST]: ({commit, dispatch}, serviceID) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_REQUEST);
            commit('addLoading', {handle: 'service'+serviceID, name: 'Load Service '+serviceID})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'service'+ serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_SUCCESS, {'resp': resp.data, 'serviceid': serviceID})
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_ERROR);
                    commit('removeLoading', 'service'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_HANDOFF_REQUEST]: ({commit, dispatch}, serviceID) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_HANDOFF_REQUEST);
            commit('addLoading', {handle: 'serviceHandoffs' + serviceID, name: 'Identifying Likely Handoffs for ' + serviceID})
            axios({url: Vue.prototype.$apiServer + 'service/' + serviceID + '/handofffinder', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'serviceHandoffs' + serviceID)
                    if (resp.data.result === 'Success' && !resp.data.message) {
                        commit(SERVICE_HANDOFF_SUCCESS, {'resp': resp.data, 'serviceid': serviceID})
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_HANDOFF_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_HANDOFF_ERROR);
                    commit('removeLoading', 'serviceHandoffs' + serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_CREATE_REQUEST]: ({commit, dispatch}, payload) => {
    //    window.console.log("Service Creation Payload: " + JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            commit(SERVICE_CREATE_REQUEST);
            commit('addLoading', {handle: 'servicecreate'+ payload.serviceid, name: 'Create Service '+ payload.serviceid})
            axios({url: Vue.prototype.$apiServer + 'service/', method: "POST", data: payload})
                .then(resp => {
                //    window.console.log("Service Creation Response:" + JSON.stringify(resp))
                    commit('removeLoading', 'servicecreate'+payload.serviceid)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_CREATE_SUCCESS, { 'payload': payload})
                        resolve(resp)
                    } else {
                        commit(SERVICE_CREATE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_CREATE_ERROR);
                    commit('removeLoading', 'servicecreate'+payload.serviceid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_UPDATE_REQUEST]: ({commit, dispatch}, payload) => {
    //    window.console.log("Service Update Payload: " + JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            commit(SERVICE_UPDATE_REQUEST);
            commit('addLoading', {handle: 'serviceupdate'+payload.serviceid, name: 'Update Service '+payload.serviceid})
            axios({url: Vue.prototype.$apiServer + 'service/'+payload.serviceid, method: "PUT", data: payload})
                .then(resp => {
                //    window.console.log("Service Update Response:" + JSON.stringify(resp))
                    commit('removeLoading', 'serviceupdate'+payload.serviceid)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_UPDATE_SUCCESS)
                        resolve(resp)
                    } else {
                        commit(SERVICE_UPDATE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_UPDATE_ERROR);
                    commit('removeLoading', 'serviceupdate'+payload.serviceid)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_MACTABLE_REQUEST]: ({commit, dispatch}, serviceID) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_MACTABLE_REQUEST);
            commit('addLoading', {handle: 'servicemacs'+serviceID, name: 'Load Service '+serviceID+ ' MAC Table'})
            axios({url: Vue.prototype.$apiServer + 'tools/mactable/'+serviceID, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicemacs'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_MACTABLE_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_MACTABLE_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_MACTABLE_ERROR);
                    commit('removeLoading', 'servicemacs'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_L1_REQUEST]: ({commit, dispatch}, serviceID) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_L1_REQUEST);
            commit('addLoading', {handle: 'servicel1'+serviceID, name: 'Load Service '+serviceID+ ' L1 Diagnostics'})
            axios({url: Vue.prototype.$apiServer + 'tools/layer1/'+serviceID, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicel1'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_L1_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_L1_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_L1_ERROR);
                    commit('removeLoading', 'servicel1'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_LIGHT_REQUEST]: ({commit, dispatch}, { serviceID }) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_LIGHT_REQUEST);
            commit('addLoading', {handle: 'servicelight'+serviceID, name: 'Load Service '+serviceID+ ' Light Levels'})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID + '/lightlevels', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicelight'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_LIGHT_SUCCESS)
                        resolve(resp.data.payload)
                    } else {
                        commit(SERVICE_LIGHT_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_LIGHT_ERROR);
                    commit('removeLoading', 'servicelight'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_TRAFFIC_REQUEST]: ({commit, dispatch}, { serviceID }) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_TRAFFIC_REQUEST);
            commit('addLoading', {handle: 'servicetraffic'+serviceID, name: 'Load Service '+serviceID+ ' ' + 'Traffic Level'})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID + '/trafficvalues', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicetraffic'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_TRAFFIC_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_TRAFFIC_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_TRAFFIC_ERROR);
                    commit('removeLoading', 'servicetraffic'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_OPERATIONALHISTORY_REQUEST]: ({commit, dispatch}, { serviceID, charttimespan}) => {
    //    window.console.log("Chart Timespan: " + charttimespan)
        return new Promise((resolve, reject) => {
            commit(SERVICE_OPERATIONALHISTORY_REQUEST);
            commit('addLoading', {handle: 'serviceoperationalhistory'+serviceID, name: 'Load Service '+serviceID+ ' Operational History'})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID + '/operationalhistory/' + charttimespan, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'serviceoperationalhistory'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_OPERATIONALHISTORY_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_OPERATIONALHISTORY_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_OPERATIONALHISTORY_ERROR);
                    commit('removeLoading', 'serviceoperationalhistory'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_LIGHTHISTORY_REQUEST]: ({commit, dispatch}, { serviceID, chartType, chartEnd, charttimespan}) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_LIGHTHISTORY_REQUEST);
            commit('addLoading', {handle: 'servicelighthistory'+chartEnd+serviceID, name: 'Load Service '+serviceID+ ' ' + chartEnd + '-end Light History'})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID + '/' + chartType + '/' + chartEnd + '/' + charttimespan, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicelighthistory'+chartEnd+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_LIGHTHISTORY_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_LIGHTHISTORY_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_LIGHTHISTORY_ERROR);
                    commit('removeLoading', 'servicelighthistory'+chartEnd+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_TRAFFICHISTORY_REQUEST]: ({commit, dispatch}, { serviceID, charttimespan}) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_TRAFFICHISTORY_REQUEST);
            commit('addLoading', {handle: 'servicetraffichistory'+serviceID, name: 'Load Service '+serviceID+ ' ' + 'Traffic History'})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID + '/traffichistory'+ '/' + charttimespan, method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicetraffichistory'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_TRAFFICHISTORY_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_TRAFFICHISTORY_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_TRAFFICHISTORY_ERROR);
                    commit('removeLoading', 'servicetraffichistory'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [SERVICE_LINKSTATUS_REQUEST]: ({commit, dispatch}, { serviceID }) => {
        return new Promise((resolve, reject) => {
            commit(SERVICE_LINKSTATUS_REQUEST);
            commit('addLoading', {handle: 'servicelink'+serviceID, name: 'Load Service '+serviceID+ ' Status'})
            axios({url: Vue.prototype.$apiServer + 'service/'+serviceID + '/linkstatus', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'servicelink'+serviceID)
                    if (resp.data.result === 'Success') {
                        commit(SERVICE_LINKSTATUS_SUCCESS)
                        resolve(resp.data)
                    } else {
                        commit(SERVICE_LINKSTATUS_ERROR)
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(SERVICE_LINKSTATUS_ERROR);
                    commit('removeLoading', 'servicelink'+serviceID)
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
};

const mutations = {
    [SERVICES_REQUEST]: state => {
        state.status = "loading";
        //Vue.set(state, "services", {});
    },
    [SERVICES_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "services", resp.payload);
    },
    [SERVICES_ERROR]: state => {
        state.status = "error";
    },
    [SERVICE_REQUEST]: () => {
        state.servicestatus = "loading";
    },
    [SERVICE_SUCCESS]: () => {
        state.servicestatus = "success";
    },
    [SERVICE_ERROR]: () => {
        state.servicestatus = "error";
    },
    [SERVICE_CREATE_REQUEST]: () => {
        state.servicecreatestatus = "loading";
    },
    [SERVICE_CREATE_SUCCESS]: () => {
        state.servicecreatestatus = "success";
    },
    [SERVICE_CREATE_ERROR]: () => {
        state.servicecreatestatus = "error";
    },
    [SERVICE_MACTABLE_REQUEST]: () => {
        state.servicemacstatus = "loading";
    },
    [SERVICE_MACTABLE_SUCCESS]: () => {
        state.servicemacstatus = "success";
    },
    [SERVICE_MACTABLE_ERROR]: () => {
        state.servicemacstatus = "error";
    },
    [SERVICE_L1_REQUEST]: () => {
        state.servicel1status = "loading";
    },
    [SERVICE_L1_SUCCESS]: () => {
        state.servicel1status = "success";
    },
    [SERVICE_L1_ERROR]: () => {
        state.servicel1status = "error";
    },
    [SERVICE_LIGHT_REQUEST]: () => {
        state.servicelightstatus = "loading";
    },
    [SERVICE_LIGHT_SUCCESS]: () => {
        state.servicelightstatus = "success";
    },
    [SERVICE_LIGHT_ERROR]: () => {
        state.servicelightstatus = "error";
    },
    [SERVICE_TRAFFIC_REQUEST]: () => {
        state.servicetrafficstatus = "loading";
    },
    [SERVICE_TRAFFIC_SUCCESS]: () => {
        state.servicetrafficstatus = "success";
    },
    [SERVICE_TRAFFIC_ERROR]: () => {
        state.servicetrafficstatus = "error";
    },
    [SERVICE_LIGHTHISTORY_REQUEST]: () => {
        state.servicelighthistorystatus = "loading";
    },
    [SERVICE_LIGHTHISTORY_SUCCESS]: () => {
        state.servicelighthistorystatus = "success";
    },
    [SERVICE_LIGHTHISTORY_ERROR]: () => {
        state.servicelighthistorystatus = "error";
    },
    [SERVICE_OPERATIONALHISTORY_REQUEST]: () => {
        state.serviceoperationalhistorystatus = "loading";
    },
    [SERVICE_OPERATIONALHISTORY_SUCCESS]: () => {
        state.serviceoperationalhistorystatus = "success";
    },
    [SERVICE_OPERATIONALHISTORY_ERROR]: () => {
        state.serviceoperationalhistorystatus = "error";
    },
    [SERVICE_HANDOFF_REQUEST]: () => {
        state.servicehandoffstatus = "loading";
    },
    [SERVICE_HANDOFF_SUCCESS]: () => {
        state.servicehandoffstatus = "success";
    },
    [SERVICE_HANDOFF_ERROR]: () => {
        state.servicehandoffstatus = "error";
    },
    [SERVICE_TRAFFICHISTORY_REQUEST]: () => {
        state.servicetraffichistorystatus = "loading";
    },
    [SERVICE_TRAFFICHISTORY_SUCCESS]: () => {
        state.servicetraffichistorystatus = "success";
    },
    [SERVICE_TRAFFICHISTORY_ERROR]: () => {
        state.servicetraffichistorystatus = "error";
    },
    [SERVICE_LINKSTATUS_REQUEST]: () => {
        state.servicelinkstatus = "loading";
    },
    [SERVICE_LINKSTATUS_SUCCESS]: () => {
        state.servicelinkstatus = "success";
    },
    [SERVICE_LINKSTATUS_ERROR]: () => {
        state.servicelinkstatus = "error";
    },
    [SERVICE_UPDATE_REQUEST]: () => {
        state.updatestatus = "loading";
    },
    [SERVICE_UPDATE_SUCCESS]: () => {
        state.updatestatus = "success";
    },
    [SERVICE_UPDATE_ERROR]: () => {
        state.updatestatus = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.services = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};