const AUTHTOKEN_LOGOUT = "AUTHTOKEN_LOGOUT";
const USERS_REQUEST = "USERS_REQUEST";
const USERS_ERROR = "USERS_ERROR";
const USERS_SUCCESS = "USERS_SUCCESS";
const USERS_ITEMS_NEEDING_ATTENTION = "USERS_ITEMS_NEEDING_ATTENTION";
const USERS_ITEMS_SUCCESS = "USERS_ITEMS_SUCCESS";
const USERS_ITEMS_ERROR = "USERS_ITEMS_ERROR";

import Vue from "vue";
import axios from 'axios';

const state = { status: "", user: {}, items: [] };

const getters = {
    getUsers: state => state.users,
    searchUsers: (state) => searchParam => {
        var searchTerm = new RegExp(searchParam, "i") //Events are searched by title, ticket number, description, and affected services
        return state.users.filter((user => searchTerm.test(user.email) || searchTerm.test(user.firstname) || searchTerm.test(user.title) || searchTerm.test(user.office) || searchTerm.test(user.mobile)))
    },
    isUserListLoaded: state => !!state.users,
    getUser: (state)=> (userId) => {
        return state.users.find(user => user.userid === userId)
    },
    getUsersByCustomer:(state)=> (custID) => {
        return state.users.filter(user => user.customerid === custID)
    },
    getItems: state => (state.items ? state.items : []),
    getItemsSites:  (state) => {
        var searchTerm = new RegExp("site", "i") //Events are searched by title, ticket number, description, and affected services
        return state.items.filter((item => searchTerm.test(item.notificationlinknode)))
    },
    getItemsServiceNotifications:  (state) => {
        var searchTerm = new RegExp("notification", "i") //Events are searched by title, ticket number, description, and affected services
        return state.items.filter((item => searchTerm.test(item.notificationlinknode)))
    },
    getItemsInductions:  (state) => {
        var searchTerm = new RegExp("site", "i") //Events are searched by title, ticket number, description, and affected services
        return state.items.filter((item => searchTerm.test(item.notificationlinknode)))
    },
};

const actions = {
    [USERS_REQUEST]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(USERS_REQUEST);
            commit('addLoading', {handle:'users',name:'Load Users'})
            axios({url: Vue.prototype.$apiServer + 'users', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'users')
                    commit(USERS_SUCCESS, resp.data);
                    if(resp.data.result === 'Success') {
                        commit(USERS_SUCCESS, resp.data);
                        resolve(resp.data)
                    } else {
                        commit(USERS_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERS_ERROR);
                    commit('removeLoading', 'users')
                    // if resp is unauthorized, logout, to
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    },
    [USERS_ITEMS_NEEDING_ATTENTION]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(USERS_ITEMS_NEEDING_ATTENTION);
            commit('addLoading', {handle:'useritems',name:'Load Items Needing Attention'})
            axios({url: Vue.prototype.$apiServer + 'itemsneedingattention', method: "GET"})
                .then(resp => {
                    commit('removeLoading', 'useritems')
                    if (resp.data.result === 'Success') {
                        commit(USERS_ITEMS_SUCCESS, resp.data);
                        resolve(resp.data)
                    } else {
                        commit(USERS_ITEMS_ERROR);
                        reject(resp.data)
                    }
                })
                .catch((err) => {
                    commit(USERS_ITEMS_ERROR);
                    commit('removeLoading', 'useritems')
                    // if resp is unauthorized, logout, to
                    //    dispatch(AUTHTOKEN_LOGOUT);
                    if (err.response.status === 403) {
                        dispatch(AUTHTOKEN_LOGOUT);
                    }
                    reject(err.response.data)
                });
        })
    }
};

const mutations = {
    [USERS_REQUEST]: state => {
        state.status = "loading";
    },
    [USERS_ITEMS_NEEDING_ATTENTION]: state => {
        state.status = "loading";
    },
    [USERS_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "users", resp.payload);
    },
    [USERS_ITEMS_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "items", resp.payload);
    },
    [USERS_ERROR]: state => {
        state.status = "error";
    },
    [USERS_ITEMS_ERROR]: state => {
        state.status = "error";
    },
    [AUTHTOKEN_LOGOUT]: state => {
        state.user = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};